import Base from "./Base/Base";
import ErrorPage from "./errorPage";
import {Navigate, redirect, RouteObject} from "react-router-dom";

import AuthBase from "./AuthBase/AuthBase";
import NewAuthBase from "./NewAuthBase/NewAuthBase";
import Signup from "./Signup/Signup";
import Login from "./Login/Login";

import Dashboard from "./Dashboard/Dashboard";
import SubscriptionPlanManager from "./SubscriptionPlanManager/SubscriptionPlanManager";
import ContentPlan from "./ContentPlan/ContentPlan";
import Articles from "./Articles/Articles";
import HowToArticles from "./Articles/HowToArticles";
import Listicles from "./Articles/Listicles";
import CustomArticles from "./Articles/CustomArticles";
import ConnectWebsite from "./ConnectWebsite/ConnectWebsite";
import ShowV2Articles from "./Articles/ShowV2Articles";
import KeywordResearch from "./KeywordsResearchV2/KeywordResearch";
import AddAutomation from "./KeywordsResearchV2/AddAutomation";
import ContentCalendar from "./ContentCalendar/ContentCalendar";
import BackLinks from "./Backlinks/BackLinks"

import axios, {AxiosError, AxiosRequestConfig} from 'axios';
import {getAccessToken, getRefreshToken, removeAccessToken, removeRefreshToken, renewAccessToken} from "../utils/jwt";
import {
	ARTICLE_EDIT_PAGE_API,
	ARTICLES_PAGE_API,
	CONNECT_WEBSITE_PAGE_API, CONTACT_US_PAGE_API,
	CONTENT_PLAN_PAGE_API,
	DASHBOARD_PAGE_API,
	KEYWORD_DETAILS_PAGE_API,
	KEYWORDS_PAGE_API,
	LOGGED_IN_BASE_PAGE_API,
	LOGOUT_PAGE_API, MAX_WEBSITES_PAGE_API,
	PLANS_PAGE_API, PROFILE_PAGE_API,
	SETTINGS_PAGE_API,
	SIGNUP_PLAN_SELECTION_API, WP_SUCCESS_PAGE_API, GOOGLE_SUCCESS_PAGE_API,
	ARCHIVE_ARTICLES_PAGE_API,
	ARCHIVE_HOW_TO_ARTICLES_PAGE_API,
	WEBFLOW_SUCCESS_PAGE_API,
	INDEXATION_PAGE_API,
	KEYWORD_RESEARCH_PAGE_API,
	CONTENT_AUTOMATION_PAGE_API,
	KEYWORD_PROJECT_KEYWORD_TITLES_PAGE_API
} from "../utils/api";
import KeywordDetails from "./KeywordDetails/KeywordDetails";
import ArticleEditor from "./ArticleEditor/ArticleEditor";
import Settings from "./Settings/Settings";
import ForgotPassword from "./AuthBase/ForgotPassword";
import ResetPassword from "./ResetPassword/ResetPassword";
import SignupPlanSelection from "./SignupPlanSelection/SignupPlanSelection";
import CheckoutSuccess from "./CheckoutSuccess/CheckoutSuccess";
import MaxWebsites from "./ConnectWebsite/MaxWebsites";
import ContactUs from "./ContactUs/ContactUs";
import AccountEmailVerification from "./AccountEmailVerification/AccountEmailVerification";
import Profile from "./Profile/Profile";
import WordpressSuccess from "./WordpressSuccess/WordpressSuccess";
import SelectedKeywords from "./SelectedKeywords/SelectedKeywords";
import ListicleKeywords from "./ListicleKeywords/ListicleKeywords";
import AiKeywordsResearch from "./AiKeywordsResearch/AiKeywordsResearch";
import CompetitorResearch from "./CompetitorResearch/CompetitorResearch";
import ArchiveArticles from "./ArchiveArticles/ArchiveArticles";
import ArchiveHowToArticles from "./ArchiveArticles/ArchiveHowToArticles";
import CompetitorResearchKeywords from "./CompetitorResearchKeywords/CompetitorResearchKeywords";
import GoogleSuccess from "./GoogleSuccess/GoogleSuccess";
import WebflowSuccess from "./WebflowSuccess/WebflowSuccess";
import Indexation from "./Indexation/Indexation";
import ResearchedKeywords from "./KeywordsResearchV2/ResearchedKeywords";
import Tutorials from "./Tutorials/Tutorials";
import GoogleLoginAndSignup from "./GoogleLoginAndSignup/GoogleLoginAndSignup";
import ShowTitlesForKeyword from "./KeywordsResearchV2/ShowTitlesForKeyword";
import Calendly from "./Calendly/Calendly";

export class RouterError extends Error {
	constructor(message: string) {
		super(`Routing Failed: ${message}`);
		this.name = "RouterError";
	}
}

/**
 * Authenticates and fetches data from provided url path. Auto-renews access token in case it was expired.
 * Redirects to login page in case access token is missing, incorrect or expired.
 *
 * You need to call this on every page on this website other than login, signup and similar pages.
 *
 * @param path - server api url to fetch data from.
 * @returns Page data if successful. Otherwise returns redirect to login page.
 */
async function getLoggedInPageData(path: string) {
	let accessToken = getAccessToken();

	if (accessToken) {
		let axiosConfig: AxiosRequestConfig = {
			method: 'get',
			url: process.env.REACT_APP_DRF_DOMAIN + path,
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			}
		}

		try {
			// axios sends all non-2xx response into catch() as an error
			let response = await axios(axiosConfig);
			if (response.data === undefined) {
				throw new RouterError("Received undefined data from the server");
			}
			return response.data;

		} catch (err) {
			const axiosError = err as AxiosError

			// encountered non-200x status codes
			if (axiosError.response?.status === 401) {
				// ----------------------- ACCESS/REFRESH TOKEN EXPIRED -----------------------
				// token expired. get new access token using refresh token
				let refreshToken: string | null = getRefreshToken();
				if (refreshToken) {
					let renewalSuccessful: boolean = await renewAccessToken(refreshToken);
					if (renewalSuccessful) {
						// renewal was successful. update access token in header value
						accessToken = getAccessToken();
						axiosConfig.headers = {
							'Authorization': 'Bearer ' + accessToken
						}
						// if it doesn't work this time, redirect to login page to prevent infinite loop
						try {
							let response = await axios(axiosConfig);
							return response.data;
						} catch (err) {
							return redirect(pageURL['login'])
						}
					} else {
						// Probably due to refresh token expiry. Redirect to login page.
						return redirect(pageURL['login'])
					}
				} else {
					// no refresh token found
					return redirect(pageURL['login']);
				}
			} else if (axiosError.response?.status === 402) {
				// ----------------------- USER HAS NOT SELECTED ANY PLAN -----------------------
				// Send them to signup plan selection page
				return redirect(pageURL['signupPlanSelection'])
			} else if (axiosError.response?.status === 302) {
				// ----------------------- OTHER REDIRECTS -----------------------
				let redirectLocation: string = (axiosError.response?.data as any)['redirect_to'];
				if (redirectLocation) {
					switch (redirectLocation) {
						case "dashboard":
							return redirect(pageURL['keywordResearch']);
						case "max_websites":
							return redirect(pageURL['maxWebsites']);
						default:
							throw new RouterError(`Unhandled redirect location ${redirectLocation}`);
					}
				} else {
					throw new RouterError("Recieved redirect status but no 'redirect_to' value");
				}

			} else {
				console.error(axiosError.response?.status);
				throw new RouterError(
					`encountered non-success status code ${axiosError.response?.status}
					 while trying to fetch logged in page data`
				)
			}
		}
	} else {
		// no jwt tokens stored in bowser (signed out)
		return redirect(pageURL['login']);
	}
}

/**
 * Similar to getLoggedInPageData() but for pages where user is logged out (no authentication is needed). In case
 * user is logged in (token present in browser, or other reasons) they will be redirected to 'dashboard' page.
 *
 * If 'path' value is not provided, only checks the access token and returns empty object.
 *
 * @param path - server api url to fetch data from.
 * @returns Page data if successful. Otherwise returns redirect to login page.
 */
async function getLoggedOutPageData(path?: string) {
	let accessToken = getAccessToken();
	if (accessToken) return redirect(pageURL['keywordResearch']);

	if (path) {
		let axiosConfig: AxiosRequestConfig = {
			method: 'get',
			url: process.env.REACT_APP_DRF_DOMAIN + path,
			responseType: 'json',
		}

		let response = await axios(axiosConfig);
		return response.data;
	} else {
		return {}
	}
}

/**
 * Logs out the user by calling logout api and also deleting the local access & refresh tokens.
 * Incase of any server or client side error, redirects to dashboard page
 */
async function logout(): Promise<Response> {
	const refreshToken: string | null = getRefreshToken();
	// no/empty refresh token found.
	if (!refreshToken) {
		// clean up just in case the keys were remaining
		removeAccessToken();
		removeRefreshToken();
		return redirect(pageURL['login']);
	}

	// call `logout api` to blacklist current tokens
	try {
		axios({
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + LOGOUT_PAGE_API,
			data: {
				'refresh': refreshToken
			},
			responseType: 'json'
		});
	} catch (err) {
		// Logout failed due to client or server error. Return to dashboard page
		console.error(err);
		return redirect(pageURL['keywordResearch'])
	}

	// delete access tokens in browser storage & redirect to login page
	removeAccessToken();
	removeRefreshToken();
	return redirect(pageURL['login']);
}


// =========================================================================================
// --------------------------- ROUTING CONFIGURATION STARTS HERE ---------------------------
// =========================================================================================

// use this only for manually redirecting users through typescript code
// for all other scenarios, use react-router `Link` component
// when adding a new URL, update both `pageURL` and `routes`
export const pageURL = {
	'signup': "/auth/signup",
	'login': "/auth/login",
	'forgotPassword': "/auth/forgot-password",
	'signupPlanSelection': "/signup-plan-selection/",
	'googleLogin': "/auth/accounts/google/login",
	'googleSignup': "/auth/accounts/google/signup",

	'resetPassword': "/reset-password/:encryptedEmail/:resetUID",
	'accountEmailVerification': "/verify-account-email/:token",

	'dashboard': "/dashboard",
	'contact': "/contact-us",
	'manageSubscription': "/manage-subscription",
	'profile': "/profile",
	'websites': "/websites",
	'settings': "/settings",
	'integrationWizard': "/integration/:service",
	'connectWebsite': "/connect-website",
	'maxWebsites': "/max-websites",
	// 'contentPlan': "/content-plan",
	// 'articles': "/articles",
	// 'howToArticles': "/how-to-articles",
	// 'listicles': "/listicles",
	// 'customArticles': "/custom-articles",
	// 'archiveHowToArticles': "/archived-how-to-articles",
	'articleEdit': "/articles/edit/:articleUID",
	// 'keywords': "/keywords",
	// 'selectedKeywords': "/keywords/selected-keywords",
	// 'listicleKeywords': "/keywords/listicles",
	// 'aiResearchKeywords': "/keywords/ai-research",
	'competitorResearch': "/keywords/competitor-research",
	'competitorResearchKeywords': "/keywords/competitor-research/:compDomain",
	// 'keywordDetails': "/keywords/:hash",
	'indexation': "/indexation",

	'wordpressSuccess': "/integration/wp/success/:encryptedDomain",
	'webflowSuccess': "/integration/webflow/success",
	"googleSuccess": "/integration/google/success",

	'checkoutSuccess': "/checkout/success",

	'integration': "/settings?tab=integration",

	'showArticles': "/show-articles",
	'keywordResearch': "/keyword-research",
	'showKeywords': "/show-keywords",
	'contentCalendar': "/content-calendar",
	'addAutomation': "/add-automation",
	'tutorials': "/tutorials",

	'showTitlesForKeyword': "/keyword-project/:keywordProjectId/titles/:keywordHash",

	'backlinks': "/backlinks",
	'get-demo': "/get-demo",
}

const routes: RouteObject[] = [
	// ---------- LOGGED OUT / AUTH PAGES ----------
	{
		path: "/auth",
		element: <NewAuthBase/>,
		errorElement: <ErrorPage/>,
		children: [
			{
				index: true,
				element: <Navigate to={pageURL['login']} replace={true}/>
			},
			{
				path: "signup",
				element: <Signup/>,
				loader: async () => {
					return getLoggedOutPageData();
				}
			},
			{
				path: "login",
				element: <Login/>,
				loader: async () => {
					return getLoggedOutPageData();
				}
			},
			{
				path: 'forgot-password',
				element: <ForgotPassword/>,
				loader: async () => {
					return getLoggedOutPageData();
				}
			},
			{
				path: pageURL['googleSignup'],
				element: <GoogleLoginAndSignup signup={true}/>,
				loader: async () => {
					return getLoggedOutPageData();
				}
			},
			{
				path: pageURL['googleLogin'],
				element: <GoogleLoginAndSignup signup={false} />,
				loader: async () => {
					return getLoggedOutPageData();
				}
			}
		]
	},
	// ---------- LOGGED IN PAGES ----------
	{
		path: "/",
		element: <Base/>,
		errorElement: <ErrorPage/>,
		id: "base",
		loader: async () => {
			return await getLoggedInPageData(LOGGED_IN_BASE_PAGE_API);
		},
		children: [
			{
				index: true,
				element: <Navigate to={pageURL['keywordResearch']} replace={true}/>
			},
			// {
			// 	path: pageURL['dashboard'],
			// 	element: <Dashboard/>,
			// 	loader: async () => {
			// 		return await getLoggedInPageData(DASHBOARD_PAGE_API);
			// 	}
			// },
			{
				path: pageURL['profile'],
				element: <Profile/>,
				loader: async () => {
					return await getLoggedInPageData(PROFILE_PAGE_API);
				}
			},
			{
				path: pageURL['contact'],
				element: <ContactUs/>,
				loader: async () => {
					return await getLoggedInPageData(CONTACT_US_PAGE_API);
				}
			},
			// {
			// 	path: pageURL['contentPlan'],
			// 	element: <ContentPlan/>,
			// 	loader: async () => {
			// 		return await getLoggedInPageData(CONTENT_PLAN_PAGE_API);
			// 	}
			// },
			// {
			// 	path: pageURL['articles'],
			// 	element: <Articles/>,
			// 	loader: async () => {
			// 		return await getLoggedInPageData(ARTICLES_PAGE_API);
			// 	}
			// },
			{
				path: pageURL['showArticles'],
				element: (
					  <ShowV2Articles />
				  ),
				loader: async () => {
					return await getLoggedInPageData(ARTICLES_PAGE_API);
				}
			},
			// {
			// 	path: pageURL['howToArticles'],
			// 	element: <HowToArticles/>,
			// 	loader: async () => {
			// 		return await getLoggedInPageData(ARTICLES_PAGE_API);
			// 	}
			// },
			// {
			// 	path: pageURL['listicles'],
			// 	element: <Listicles/>,
			// 	loader: async () => {
			// 		return await getLoggedInPageData(ARTICLES_PAGE_API);
			// 	}
			// },
			// {
			// 	path: pageURL['customArticles'],
			// 	element: <CustomArticles/>,
			// 	loader: async () => {
			// 		return await getLoggedInPageData(ARTICLES_PAGE_API);
			// 	}
			// },
			{
				path: pageURL['archiveArticles'],
				element: <ArchiveArticles/>,
				loader: async () => {
					return await getLoggedInPageData(ARCHIVE_ARTICLES_PAGE_API);
				}
			},
			// {
			// 	path: pageURL['archiveHowToArticles'],
			// 	element: <ArchiveHowToArticles/>,
			// 	loader: async () => {
			// 		return await getLoggedInPageData(ARCHIVE_HOW_TO_ARTICLES_PAGE_API);
			// 	}
			// },
			{
				path: pageURL['articleEdit'],
				element: <ArticleEditor/>,
				loader: async ({params}) => {
					return await getLoggedInPageData(ARTICLE_EDIT_PAGE_API + "?article_uid=" + params.articleUID);
				}
			},
			// {
			// 	path: pageURL['selectedKeywords'],
			// 	element: <SelectedKeywords/>,
			// 	loader: async () => {
			// 		return await getLoggedInPageData(KEYWORDS_PAGE_API);
			// 	},
			// },
			// {
			// 	path: pageURL['listicleKeywords'],
			// 	element: <ListicleKeywords/>,
			// 	loader: async () => {
			// 		return await getLoggedInPageData(KEYWORDS_PAGE_API);
			// 	},
			// },
			// {
			// 	path: pageURL['aiResearchKeywords'],
			// 	element: <AiKeywordsResearch/>,
			// 	loader: async () => {
			// 		return await getLoggedInPageData(KEYWORDS_PAGE_API);
			// 	},
			// },
			{
				path: pageURL['keywordResearch'],
				element: <KeywordResearch />,
				loader: async () => {
					return await getLoggedInPageData(KEYWORD_RESEARCH_PAGE_API);
				},
			},
			{
				path: pageURL['addAutomation'],
				element: <AddAutomation />,
				loader: async () => {
					return await getLoggedInPageData(CONTENT_AUTOMATION_PAGE_API);
				},
			},
			// {
			// 	path: pageURL['showKeywords'],
			// 	element: <ResearchedKeywords/>,
			// 	loader: async () => {
			// 		return await getLoggedInPageData(KEYWORDS_PAGE_API);
			// 	},
			// },
			{
				path: pageURL['contentCalendar'],
				element: <ContentCalendar/>,
				loader: async () => {
					return await getLoggedInPageData(ARTICLES_PAGE_API);
				}
			},
			{
				path: pageURL['competitorResearch'],
				element: <CompetitorResearch/>,
				loader: async () => {
					return await getLoggedInPageData(KEYWORDS_PAGE_API);
				},
			},
			{
				path: pageURL['competitorResearchKeywords'],
				element: <CompetitorResearchKeywords/>,
				loader: async () => {
					return await getLoggedInPageData(KEYWORDS_PAGE_API);
				},
			},
			// {
			// 	path: pageURL['keywordDetails'],
			// 	element: <KeywordDetails/>,
			// 	loader: async () => {
			// 		return await getLoggedInPageData(KEYWORD_DETAILS_PAGE_API);
			// 	}
			// },
			{
				path: pageURL['manageSubscription'],
				element: <SubscriptionPlanManager/>,
				loader: async () => {
					return await getLoggedInPageData(PLANS_PAGE_API);
				}
			},
			{
				path: pageURL['settings'],
				element: <Settings/>,
				loader: async () => {
					return await getLoggedInPageData(SETTINGS_PAGE_API);
				}
			},
			{
				path: pageURL['indexation'],
				element: <Indexation/>,
				loader: async () => {
					return await getLoggedInPageData(INDEXATION_PAGE_API);
				}
			},
			{
				path: pageURL['tutorials'],
				element: <Tutorials />,
				loader: async () => {
					return await getLoggedInPageData(PROFILE_PAGE_API);
				}
			},
			{
				path: pageURL['showTitlesForKeyword'],
				element: (
					  <ShowTitlesForKeyword />
				  ),
				loader: async ({params}) => {
					return await getLoggedInPageData(KEYWORD_PROJECT_KEYWORD_TITLES_PAGE_API + `?keyword_project_id=${params.keywordProjectId}&keyword_hash=${params.keywordHash}`);
				}
			},
			{
				path: pageURL['backlinks'],
				element:<BackLinks/>,
				loader: async () => {
					return getLoggedInPageData("/api/frontend/get-backlink/");
				}
			},
			{
				path: pageURL['get-demo'],
				element:<Calendly />,
				loader: async () => {
					return getLoggedInPageData(PROFILE_PAGE_API);
				}
			},
		]
	},
	// ---------- CONNECT WEBSITE WIZARD ----------
	{
		path: pageURL['connectWebsite'],
		element: <ConnectWebsite/>,
		errorElement: <ErrorPage/>,
		loader: async () => {
			return await getLoggedInPageData(CONNECT_WEBSITE_PAGE_API);
		}
	},
	{
		path: pageURL['maxWebsites'],
		element: <MaxWebsites/>,
		errorElement: <ErrorPage/>,
		loader: async () => {
			return await getLoggedInPageData(MAX_WEBSITES_PAGE_API);
		}
	},
	// ---------- WP INTEGRATION SUCCESS ----------
	{
		path: pageURL['wordpressSuccess'],
		element: <WordpressSuccess/>,
		errorElement: <ErrorPage/>,
		loader: async ({request, params}) => {
			const encryptedDomain: string | undefined = params.encryptedDomain;
			const siteURL = new URL(request.url).searchParams.get('site_url');
			const userLogin = new URL(request.url).searchParams.get('user_login');
			const password = new URL(request.url).searchParams.get('password');
			return getLoggedInPageData(WP_SUCCESS_PAGE_API + `?ed=${encryptedDomain}&surl=${siteURL}&ul=${userLogin}&k=${password}`);
		}
	},
	// ---------- WEBFLOW INTEGRATION SUCCESS ----------
	{
		path: pageURL['webflowSuccess'],
		element: <WebflowSuccess/>,
		errorElement: <ErrorPage/>,
		loader: async ({request}) => {
			const url = new URL(request.url);
			const state = url.searchParams.get('state');
			const code = url.searchParams.get('code');
			return getLoggedInPageData(WEBFLOW_SUCCESS_PAGE_API + `?state=${state}&code=${code}`);
		}
	},
	// ---------- GOOGLE INTEGRATION SUCCESS ----------
	{
		path: pageURL['googleSuccess'],
		element: <GoogleSuccess/>,
		errorElement: <ErrorPage/>,
		loader: async ({request}) => {
			const state = new URL(request.url).searchParams.get('state');
			const code = new URL(request.url).searchParams.get('code');
			const scope = new URL(request.url).searchParams.get('scope');
			const integrationType = localStorage.getItem('integration-type');
			return getLoggedInPageData(GOOGLE_SUCCESS_PAGE_API + `?state=${state}&code=${code}&scope=${scope}&integration-type=${integrationType}`);
		}
	},

	// ---------- CHECKOUT SUCCESS ----------
	{
		path: pageURL['checkoutSuccess'],
		element: <CheckoutSuccess/>,
		errorElement: <ErrorPage/>,
	},
	// ---------- MISC. PAGES ----------
	{
		path: pageURL['signupPlanSelection'],
		element: <SignupPlanSelection/>,
		loader: async () => {
			return getLoggedInPageData(SIGNUP_PLAN_SELECTION_API);
		}
	},
	{
		path: pageURL['resetPassword'],
		element: <ResetPassword/>
	},
	{
		path: pageURL['accountEmailVerification'],
		element: <AccountEmailVerification/>
	},
	{
		path: "/logout",
		loader: async () => {
			return await logout();
		}
	},
]

export default routes;
