import wordpressLogo from "../../assets/images/wordpress.png";
import webflowLogo from "../../assets/images/webflow.png";
import wixLogo from "../../assets/images/wix.png";
// import googleAnalyticsLogo from "../../assets/images/google-analytics.png"
// import googleDriveLogo from "../../assets/images/google-drive.png"
import googleSearchConsoleLogo from "../../assets/images/google-search-console.png"
import abunLogo from "../../assets/images/branding/abun_blue_text_logo.png";
import plusSign from "../../assets/images/plus.png"
import { MutableRefObject, useState } from "react";
import GenericButton from "../../components/GenericButton/GenericButton";
import AbunModal from "../../components/AbunModal/AbunModal";
import Input from "../../components/Input/Input";
import { useMutation } from "@tanstack/react-query";
import { useRouteLoaderData, useNavigate } from "react-router-dom";
import {
	disableAutoPublishMutation,
	enableOrEditAutoPublishMutation,
	removeIntegrationMutation,
	wordpressIntegrationMutation,
	googleIntegrationMutation,
	webflowIntegrationMutation,
	wixIntegrationMutation,
	getWebflowSites,
	getWordpressSites,
	getWixSites
} from "../../utils/api";
import AbunButton from "../../components/AbunButton/AbunButton";
import { Tooltip } from "react-tooltip";
import Icon from "../../components/Icon/Icon";
import { BasePageData } from "../Base/Base";
import { NavLink } from "react-router-dom";
import AbunTable from "../../components/AbunTable/AbunTable";
import {ColumnDef, createColumnHelper} from "@tanstack/react-table";

export type PostingDays = 'all_days' | 'weekdays' | 'weekends' | 'alternate_days' | 'schedule_randomly';
export type PostingTime = 'morning' | 'afternoon' | 'evening' | 'night' | 'post_anytime';
export type Integrations = 'wordpress' | 'webflow' | 'wix';

interface WordpressIntegrationResponse {
	success: boolean
	authorization_endpoint: string
	message?: string
}

interface WebflowIntegrationBasedOnAppResponse {
	success: boolean
	authorization_endpoint: string
}

interface WebflowIntegrationBasedOnApiResponse {
	success: boolean
	message: string
	collections?: Array<WebflowIntgrationCollection>
	fields?: Array<WebflowCollectionField>
	required_feilds?: number
}

interface wixIntegrationResponse {
	success: boolean
	message: string
}

interface GoogleIntegrationResponse {
	success: boolean
	authorization_endpoint: string
}


interface IntegrationProps {
	errorAlertRef: MutableRefObject<any>
	successAlertRef: MutableRefObject<any>
	googleSearchConsoleIntegration: boolean
	googleAnalyticsIntegration: boolean
	googleDriveIntegration: boolean
	currentPlanName: string
	allIntegrations: Array<string>
	updatePageData: () => void
}

type WebflowIntgrationCollection = {
	collection_id: string
	display_name: string
}

type WebflowCollectionField = {
	field_id: string
	display_name: string
	slug: string
	required: boolean
	field_type: string
	validations: Array<string>
}

type WebflowSelectedFields = {
	code: string,
	value: string,
	validations: Array<string>
}

const WebflowFiledsMappingOptions = [
	{
		displayName: "Article Content",
		code: "article_content",
		requiredFieldType: "RichText"
	},
	{
		displayName: "Feature Image",
		code: "featue_image",
		requiredFieldType: "Image"
	},
	{
		displayName: "Article Short Summary",
		code: "article_short_summary",
		requiredFieldType: "PlainText"
	},
	{
		displayName: "Article Posted Date",
		code: "article_posted_date",
		requiredFieldType: "DateTime"
	},
	{
		displayName: "Article Slug",
		code: "article_slug",
		requiredFieldType: "PlainText"
	},
	{
		displayName: "Article Title",
		code: "article_title",
		requiredFieldType: "PlainText"
	},
]

export function Integration(props: IntegrationProps) {
	// ---------------------------- STATES ----------------------------
	const navigate = useNavigate();

	const [
		hasArticlesIntegration,
		setHasArticlesIntegration
	] = useState(props.allIntegrations.length > 0);

	const [
		connectedArticleIntegrations,
		setConnectedArticleIntegrations
	] = useState(props.allIntegrations);

	const [
		hasGoogleSearchConsoleIntegration,
		setHasGoogleSearchConsoleIntegration
	] = useState(props.googleSearchConsoleIntegration);

	const [
		hasGoogleAnalyticsIntegration,
		setHasGoogleAnalyticsIntegration
	] = useState(props.googleAnalyticsIntegration);

	const [
		hasGoogleDriveIntegration,
		setHasGoogleDriveIntegration
	] = useState(props.googleDriveIntegration);

	const [
		integrationDeleteModal,
		setIntegrationDeleteModal
	] = useState({active: false, integration: ""});

	const [
		integrationViewSitesModal,
		setIntegrationViewSitesModal
	] = useState({active: false, integration: "", modelWidth: ""});

	const [
		integrationUniqueID,
		setIntegrationUniqueID
	] = useState("");

	const [integrationModalActive, setIntegrationModalActive] = useState(false);
	const [autopostModalActive, setAutopostModalActive] = useState(false);
	const [modalContent, setModalContent] = useState<Integrations>('wordpress');
	const [wpSiteURL, setWpSiteURL] = useState("");
	const [wpUsername, setWpUsername] = useState("");
	const [wpPassword, setWpPassword] = useState("");
	const [wfAPIToken, setWfAPIToken] = useState("");
	const [wixAPIToken, setWixAPIToken] = useState("");
	const [wixSiteID, setWixSiteID] = useState("");
	const [integrationError, setIntegrationError] = useState("");
	const [integrationProcessing, setIntegrationProcessing] = useState({ name: "" });
	const [allArticlesIntegrations, _] = useState(["wordpress", "webflow", "wix"])
	const [GSCIntegrationProcessing, setGSCIntegrationProcessing] = useState(false);
	const [GAIntegrationProcessing, setGAIntegrationProcessing] = useState(false);
	const [GDIntegrationProcessing, setGDIntegrationProcessing] = useState(false);
	const [disableAllGoogleIntegrationButtons, setDisableAllGoogleIntegrationButtons] = useState(false);

	// For webflow integration
	const [webflowCollectionsList, setWebflowCollectionsList] = useState<Array<WebflowIntgrationCollection>>([]);
	const [webflowCollectionFields, setWebflowCollectionFields] = useState<Array<WebflowCollectionField>>([]);
	const [selectedWebflowFields, setSelectedWebflowFields] = useState<Array<WebflowSelectedFields>>([]);
	const [webflowAPICommand, setWebflowAPICommand] = useState("authenticate");
	const [webflowAPIAuthenticated, setWebflowAPIAuthenticated] = useState(false);
	const [selectedWebflowCollectionID, setSelectedWebflowCollectionID] = useState("");
	const [totalFeidlsRequired, setTotalFeidlsRequired] = useState(0);
	const [selectedRequiredFields, setSelectedRequiredFields] = useState<Array<string>>([]);

	// For table data
	const [tableColumnDefs, setTableColumnDefs] = useState<Array<ColumnDef<any, any>>>([]);
	const [tableData, setTableData] = useState([]);
	const [tableDataLoading, setTableDataLoading] = useState(true);

	const baseData = useRouteLoaderData("base") as BasePageData;

	// ---------------------------- MUTATIONS ----------------------------
	const enableOrEditAutoPublish = useMutation(enableOrEditAutoPublishMutation);
	const disableAutoPublish = useMutation(disableAutoPublishMutation);
	const wordpressIntegrationMut = useMutation(wordpressIntegrationMutation);
	const webflowIntegrationMut = useMutation(webflowIntegrationMutation);
	const wixIntegrationMut = useMutation(wixIntegrationMutation);
	const googleIntegrationMut = useMutation(googleIntegrationMutation);
	const removeIntegrationMut = useMutation(removeIntegrationMutation);

	// ---------------------------- FUNCTIONS ----------------------------
	/**
	 * Starts process for adding Wordpress integration to user website.
	 */
	function launchWordpressIntegrationWizard() {
		console.log("launch");
		setModalContent('wordpress');
		setIntegrationModalActive(true);
		setIntegrationError("");
	}

	/**
	 * Starts process for adding Webflow integration to user website based on API.
	 */
	function launchWebflowIntegrationWizard() {
		console.log("launch");
		setModalContent('webflow');
		setIntegrationModalActive(true);
		setIntegrationError("");
	}

	/**
	 * Starts process for adding Webflow integration to user website based on APP.
	 */
	function startWebflowIntegration() {
		if (hasArticlesIntegration) {
			return null;
		}

		props.errorAlertRef.current?.close();
		setIntegrationProcessing({ name: "webflow" });
		webflowIntegrationMut.mutate({ based_on: "app" }, {
			onSuccess: (data) => {
				const response: WebflowIntegrationBasedOnAppResponse = data['data'];
				if (response.success) {
					window.location.href = response.authorization_endpoint;
				} else {
					setIntegrationProcessing({ name: "" });
					props.errorAlertRef.current?.show(
						"Oops! Something went wrong :( Please try " +
						"again later or contact us for further support."
					);
				}
			},
			onError: () => {
				setIntegrationProcessing({ name: "" });
				props.errorAlertRef.current?.show(
					"Oops! Something went wrong :( Please try " +
					"again later or contact us for further support."
				);
			}
		})
	}

	/**
	 * Starts process for adding Wix integration to user website.
	 */
	function launchWixIntegrationWizard() {
		console.log("launch");
		setModalContent('wix');
		setIntegrationModalActive(true);
		setIntegrationError("");
	}

	/**
	 * Removes all integration from user website
	 */
	function removeAllIntegrations() {
		removeIntegrationMut.mutate({
			integrationType: integrationDeleteModal.integration,
			integrationUniqueID: integrationUniqueID
		}, {
			onSuccess: () => {
				if (allArticlesIntegrations.includes(integrationDeleteModal.integration)){
					removeIntegration(integrationDeleteModal.integration);
					setHasArticlesIntegration(false);
					setIntegrationDeleteModal({ active: false, integration: "" });
				} else {
					switch (integrationDeleteModal.integration) {
						case "google-search-console":
							setHasGoogleSearchConsoleIntegration(false);
							setIntegrationDeleteModal({ active: false, integration: "" });
							break;
						case "google-analytics":
							setHasGoogleAnalyticsIntegration(false);
							setIntegrationDeleteModal({ active: false, integration: "" });
							break;
						case "google-drive":
							setHasGoogleDriveIntegration(false);
							setIntegrationDeleteModal({ active: false, integration: "" });
							break;
						default:
							console.error(`Invalid integration '${integrationDeleteModal.integration}'`);

					}
				}
			}
		})
	}

	/**
	 * Starts process to connect google search console, google analytics or google drive to user website.
	 */
	function googleIntegration(integrationType: "google-search-console" | "google-analytics" | "google-drive") {
		props.errorAlertRef.current?.close();
		setGoogleIntegrationProcessing(integrationType, true);
		googleIntegrationMut.mutate(integrationType, {
			onSuccess: (data) => {
				const response: GoogleIntegrationResponse = data['data'];
				if (response.success) {
					localStorage.setItem('integration-type', integrationType);
					window.location.href = response.authorization_endpoint;
				} else {
					setGoogleIntegrationProcessing(integrationType, false);
					props.errorAlertRef.current?.show(
						"Oops! Something went wrong :( Please try " +
						"again later or contact us for further support."
					);
				}
			},
			onError: () => {
				setGoogleIntegrationProcessing(integrationType, false);
				props.errorAlertRef.current?.show(
					"Oops! Something went wrong :( Please try " +
					"again later or contact us for further support."
				);
			}
		})
	}

	/**
	 * Sets the GA or GSC integration
	*/
	function setGoogleIntegrationProcessing(integrationType: string, value: boolean) {
		setDisableAllGoogleIntegrationButtons(value);
		switch (integrationType) {
			case "google-search-console":
				setGSCIntegrationProcessing(value);
				break;
			case "google-drive":
				setGDIntegrationProcessing(value);
				break;
			default:
				setGAIntegrationProcessing(value);
		}
	}

	// ===================================================================
	// ---------------------------- MAIN CODE ----------------------------
	// ===================================================================

	function integrationModalContent() {
		switch (modalContent) {
			case "wordpress":
				return wordpressModalContent();
			case "webflow":
				return webflowModalContent();
			case "wix":
				return wixModalContent();
			default:
				return <p>{modalContent} is not supported at the moment.</p>
		}
	}

	function wordpressModalContent() {
		return (
			<>
				<div className={"integration-modal-logo"}>
					<img src={abunLogo} alt="abun_logo" width={"auto"} height={64} />
					<img src={plusSign} className={"plus"} alt="+" width={"auto"} height={32} />
					<img src={wordpressLogo} alt="wp_logo" width={"auto"} height={64} />
				</div>
				<div className={"block"}>
					<p>
						To integrate Abun with your Wordpress site, enter your wordpress site url&nbsp;
						<b>-{'>'}</b> Click on Connect&nbsp;
						<b>-{'>'}</b> Login to your WP Admin page (if prompted)&nbsp;
						<b>-{'>'}</b> Approve the connection request.
					</p>
				</div>
				<div className={"block"}>
					<p>
						Once done, you will be redirected to Abun showing a success message.
					</p>
				</div>
				<div className={"block"}>
					<p className={"font-secondary"}>
						<b className={"is-underlined"}>IMPORTANT:</b> Make sure your wordpress site is
						running on <b>https</b> and Application Password feature is enabled.
					</p>
				</div>
				<div className={"mt-5"}>
					<label className={"label"}>
						Wordpress Site URL:
						<Input value={wpSiteURL}
							type={"text"}
							placeholder={"ex. yourdomain.com"}
							onChange={(val) => {
								setWpSiteURL(val);
							}} />
					</label>
				</div>
				<h3 className="is-6 mt-5 mb-5" style={{fontWeight: "600"}}>OR, Enter your Wordpress username and Application password to connect your site.</h3>
				<p className="is-6 mt-5 mb-5">
					1. <b>Log in:</b> Go to 'yourdomain.com/wp-admin' and log in with an admin account.
				</p>
				<p className="is-6 mt-5 mb-5">
					2. <b>Go to Profile:</b> Click <b>Users {'>'} Profile</b>.
				</p>
				<p className="is-6 mt-5 mb-5">
					3. <b>Find Application Password:</b> Scroll down to the <b>Application Passwords</b> section (ensure you're on WordPress 5.6+).
				</p>
				<p className="is-6 mt-5 mb-5">
					4. <b>Create a Password:</b> Enter a name(e.g, "Abun Auto Blog") and click <b>Add New Application Password</b>.
				</p>
				<p className="is-6 mt-5">
					5. <b>Copy the generated Application password</b>.
				</p>
				<div className={"mt-2"}>
					<label className={"label"}>
						Username or Email:
						<Input value={wpUsername}
							type={"text"}
							placeholder={"Enter your username or email"}
							onChange={(val) => {
								setWpUsername(val);
							}} />
					</label>
					<label className={"label"}>
						Application Password:
						<Input value={wpPassword}
							type={"text"}
							placeholder={"Enter your application password"}
							onChange={(val) => {
								setWpPassword(val);
							}} />
					</label>
				</div>
				<GenericButton text={wordpressIntegrationMut.isLoading ? "Please Wait..." : "Connect"}
					type={"success"}
					disable={wordpressIntegrationMut.isLoading}
					additionalClassList={["mt-6", "pl-6", "pr-6"]}
					clickHandler={() => {
						setIntegrationError("");

						// add https with domain
						let wpSiteHttpsURL: string;
						try {
							const parser = new URL(wpSiteURL);
							// remove 'www.'
							parser.hostname = parser.hostname.replace("www.", "");
							wpSiteHttpsURL = `https://${parser.hostname}`;
						} catch {
							wpSiteHttpsURL = `https://${wpSiteURL}`;
						}

						if (wpSiteURL && wpUsername && wpPassword) {
							wordpressIntegrationMut.mutate({
								wpSiteURL: wpSiteHttpsURL,
								wpUsername: wpUsername,
								wpPassword: wpPassword
							}, {
								onSuccess: (data) => {
									let response: WordpressIntegrationResponse = data['data'];
									if (response.success) {
										setIntegrationModalActive(false);
										setHasArticlesIntegration(true);
										setConnectedArticleIntegrations([...connectedArticleIntegrations, "wordpress"]);
									} else {
										if (response.message) {
											setIntegrationError(response.message);
										} else {
											setIntegrationError("Oops! Something went wrong. Please try again in some time.");
										}
									}
								},
								onError: () => {
									setIntegrationError("Oops! Something went wrong. Please try again in some time.");
								}
							})
						} else if (wpSiteURL && !wpUsername && !wpPassword) {

							wordpressIntegrationMut.mutate({
								wpSiteURL: wpSiteHttpsURL
							}, {
								onSuccess: (data) => {
									let response: WordpressIntegrationResponse = data['data'];
									if (response.success) {
										// Send users to wordpress app connection authorization page.
										window.location.href = response.authorization_endpoint;
										// console.log(response.authorization_endpoint);
									} else {
										setIntegrationError(
											`We were unable to connect with ${wpSiteHttpsURL}. Please make sure you are using 
											https and that Application Password feature is turned ON.`
										);
									}
								},
								onError: (response) => {
									if (response.message) {
										setIntegrationError(response.message)
									} else {
										setIntegrationError("Oops! Something went wrong. Please try again in some time.");
									}
								}
							}); 
						} else {
							if (!wpSiteURL) {
								setIntegrationError("Please provide your wordpress site URL");
							} else if (!wpUsername) {
								setIntegrationError("Please provide your wordpress username or email");
							} else if (!wpPassword) {
								setIntegrationError("Please provide your wordpress application password");
							}
						}
					}}/>

				<p className={"has-text-danger mt-4"}>{integrationError}</p>
			</>
		)
	}

	function webflowModalContent() {
		return (
			<>
				<div className={"integration-modal-logo"}>
					<img src={abunLogo} alt="abun_logo" width={"auto"} height={64} />
					<img src={plusSign} className={"plus"} alt="+" width={"auto"} height={32} />
					<img src={webflowLogo} alt="wp_logo" width={"auto"} height={64} />
				</div>
				<div className={"block"}>
					<h1 className="title is-4 mb-3">Integrating Abun with Your Webflow Site</h1>
					<p>To get started, follow these steps: </p>
					<ol className="ml-5 mt-2 integration-steps">
						<li>Log in to your Webflow dashboard.</li>
						<li>Open the site settings for the site you want to connect with Abun.</li>
						<li>Select "Apps & Integrations" from the left-hand menu.</li>
						<li>In the "API Access" section, click "Generate API Token".</li>
						<li>Set the following permissions:</li>
						<ul className="ml-5 integration-permissions">
							<li>Assets: Read and write</li>
							<li>CMS: Read and write</li>
							<li>Sites: Read and write</li>
							<li>Authorized user: Read-only</li>
						</ul>
						<li>Copy the generated APl token.</li>
					</ol>
				</div>
				<div className={"mt-5"}>
					<label className={"label"}>
						Webflow API token:
						<Input value={wfAPIToken}
							type={"text"}
							placeholder={"ex. 86ac5cefa3***************cd88"}
							onChange={(val) => {
								setWfAPIToken(val);
							}} />
					</label>
					<GenericButton text={webflowIntegrationMut.isLoading && webflowAPICommand == "authenticate" ? "Authenticating..." : webflowAPIAuthenticated ? "Authenticated!" : "Authenticate"}
						type={"success"}
						disable={webflowIntegrationMut.isLoading && webflowAPICommand === "authenticate"}
						additionalClassList={["mt-3", "pl-6", "pr-6"]}
						clickHandler={() => {
							setIntegrationError("");
							setWebflowAPICommand("authenticate");
							setWebflowAPIAuthenticated(false);
							setWebflowCollectionsList([]);
							setWebflowCollectionFields([]);

													if (!wfAPIToken){
														setIntegrationError("Please provide webflow API token")
													} else {
														webflowIntegrationMut.mutate({based_on: "api", api_token: wfAPIToken, command: "authenticate"}, {
															onSuccess: (data) => {
																let response: WebflowIntegrationBasedOnApiResponse = data['data'];
																if (response.success) {
																	setWebflowAPIAuthenticated(true);
																	setWebflowCollectionsList(response.collections as Array<WebflowIntgrationCollection>);
																} else {
																	setIntegrationError(response.message);
																}
															},
															onError: () => {
																setIntegrationError("Oops! Something went wrong. Please try again in some time.");
															}
														});
													}
												}}/>
					<label className={"label mt-6"}>
						Webflow Collection
						<br />
						<div className="select" style={{ width: "100%" }}>
							<select disabled={!webflowCollectionsList} defaultValue={"Please Select a CMS Collection"} style={{ width: "100%" }}
								onChange={(e) => {
									let collection_id = e.target.value;

									setIntegrationError("");
									setWebflowAPICommand("fields");
									setWebflowCollectionFields([]);
									setSelectedWebflowFields([]);
									setSelectedRequiredFields([]);

									if (!collection_id) {
										setWebflowCollectionFields([]);
									} else {
										setSelectedWebflowCollectionID(e.target.value);
										webflowIntegrationMut.mutate({ based_on: "api", api_token: wfAPIToken, command: "fields", collection_id: collection_id }, {
											onSuccess: (data) => {
												let response: WebflowIntegrationBasedOnApiResponse = data['data'];
												if (response.success) {
													setWebflowCollectionFields(response.fields as Array<WebflowCollectionField>);
													setTotalFeidlsRequired(!response.required_feilds ? 0 : response.required_feilds);
													setWebflowAPICommand("connect");
												} else {
													setWebflowCollectionFields([]);
													setIntegrationError(response.message);
												}
											},
											onError: () => {
												setIntegrationError("Oops! Something went wrong. Please try again in some time.");
											}
										});
									}
								}}>
								<option key={1} value={""}>Please select a CMS Collection</option>
								{webflowCollectionsList?.map(collection => (
									<option key={collection.collection_id} value={collection.collection_id}>{collection.display_name}</option>
								))}
							</select>
						</div>
					</label>
					{webflowCollectionFields.length > 0 &&
						webflowCollectionFields.map((field) => (
							<label key={field.field_id} className={"label mt-4"}>
								{field.display_name} {field.required ? <span className="has-text-danger">*</span> : ""}
								<br />
								<div className="select" style={{ width: "100%" }}>
									<select defaultValue={"Please map an option with this field"} style={{ width: "100%" }}
										onChange={(e) => {
											let field_code = e.target.value;
											if (field_code) {
												setSelectedWebflowFields([...selectedWebflowFields, { code: field_code, value: field.slug, validations: field.validations }]);
												if (field.required && !selectedRequiredFields.includes(field.slug)) {
													setSelectedRequiredFields(prev => [...prev, field.slug]);
												}
											} else {
												setSelectedWebflowFields((prev) => (prev.filter(item => item.value !== field.slug)));
												setSelectedRequiredFields((prev) => (prev.filter(slug => slug !== field.slug)));
											}
										}}>
										<option key={1} value={""}>Please map an option with this field</option>
										{WebflowFiledsMappingOptions?.map(option => (
											(field.field_type === option.requiredFieldType) &&
											<option key={option.code} value={option.code}>{option.displayName}</option>
										))}
									</select>
								</div>
							</label>
						))
					}
					<GenericButton text={webflowIntegrationMut.isLoading && webflowAPICommand === "connect" && selectedRequiredFields.length === totalFeidlsRequired ? "Please Wait..." : "Connect"}
											 type={"success"}
											 disable={webflowIntegrationMut.isLoading  || webflowAPICommand !== "connect" || selectedRequiredFields.length !== totalFeidlsRequired}
											 additionalClassList={["mt-5", "pl-6", "pr-6"]}
											 clickHandler={() => {
												 setIntegrationError("");
												 webflowIntegrationMut.mutate({
													based_on: "api",
													api_token: wfAPIToken,
													command: "connect",
													collection_id: selectedWebflowCollectionID,
													selected_feilds_mapping: selectedWebflowFields,
												 }, {
													 onSuccess: (data) => {
														 let response: WebflowIntegrationBasedOnApiResponse = data['data'];
														 if (response.success) {
															 props.successAlertRef.current?.show(`Your Webflow account connected to abun successfully!`);
															 setIntegrationModalActive(false);
															 setHasArticlesIntegration(true);
															 setConnectedArticleIntegrations([...connectedArticleIntegrations, "webflow"])
														 } else {
															 setIntegrationError(response.message);
														 }
													 },
													 onError: () => {
														 setIntegrationError("Oops! Something went wrong. Please try again in some time.");
													 }
												 });
											 }}/>
				</div>
				<p className={"has-text-danger mt-4"}>{integrationError}</p>
			</>
		)
	}

	function wixModalContent() {
		return (
			<>
				<div className={"integration-modal-logo"}>
					<img src={abunLogo} alt="abun_logo" width={"auto"} height={64} />
					<img src={plusSign} className={"plus"} alt="+" width={"auto"} height={32} />
					<img src={wixLogo} alt="wp_logo" width={"auto"} height={64} />
				</div>
				<div className={"block"}>
					<h1 className="title is-4 mb-3">Integrating Abun with Your WIX Site</h1>
					<p>To get started, follow these steps: </p>
					<ol className="ml-5 mt-2 integration-steps">
						<li>Log in to your WIX site dashboard.</li>
						<li>Copy the Site ID from the URL.</li>
						<li>After this go to account settings.</li>
						<li>In the "API Keys" section, click "Generate API Key".</li>
						<li>Check the following permissions:</li>
						<ul className="ml-5 integration-permissions">
							<li>WIX Blogs from "All site permissions"</li>
						</ul>
						<li>Copy the generated APl key.</li>
					</ol>
				</div>
				<div className={"mt-5"}>
					<label className={"label"}>
						WIX Site ID:
						<Input value={wixSiteID}
							type={"text"}
							placeholder={"ex. cc745-2f6***************c1ea-7bb2c2e1"}
							onChange={(val) => {
								setWixSiteID(val);
							}} />
					</label>
					<label className={"label mt-5"}>
						WIX API key:
						<Input value={wixAPIToken}
							type={"text"}
							placeholder={"ex. IST.eyJraWQiOiJQ***************c3no3w"}
							onChange={(val) => {
								setWixAPIToken(val);
							}} />
					</label>
					<GenericButton text={wixIntegrationMut.isLoading ? "Connecting..." : "Connect"}
						type={"success"}
						disable={webflowIntegrationMut.isLoading}
						additionalClassList={["mt-6", "mb-2", "pl-6", "pr-6"]}
						clickHandler={() => {
							setIntegrationError("");

							if (!wixAPIToken) {
								setIntegrationError("Please provide WIX API key")
							} else if (!wixSiteID) {
								setIntegrationError("Please provide WIX Site ID")
							} else {
								wixIntegrationMut.mutate({ api_token: wixAPIToken, site_id: wixSiteID }, {
									onSuccess: (data) => {
										let response: wixIntegrationResponse = data['data'];
										if (response.success) {
											setIntegrationModalActive(false);
											setHasArticlesIntegration(true);
											setConnectedArticleIntegrations([...connectedArticleIntegrations, "wix"]);
										} else {
											setIntegrationError(response.message);
										}
									},
									onError: () => {
										setIntegrationError("Oops! Something went wrong. Please try again in some time.");
									}
								});
							}
						}} />
				</div>
				<p className={"has-text-danger mt-4"}>{integrationError}</p>
			</>
		)
	}

	function integrationExists(integrationName: string){
		return connectedArticleIntegrations.some(integration => integration.includes(integrationName))
	}

	function removeIntegration(integrationName: string){
		const index = connectedArticleIntegrations.findIndex(integration => integration.includes(integrationName));
		const newConnectedArticleIntegrations = [...connectedArticleIntegrations];
		newConnectedArticleIntegrations.splice(index, 1);
		setConnectedArticleIntegrations(newConnectedArticleIntegrations);
	}

	function setUpTableForWebflow(){
		interface WebflowTable {
			site_id: string,
			collection_id: string,
			site_url: string,
		}

		// -------------------------- TABLE SETUP --------------------------
		setTableDataLoading(true);

		const columnHelper = createColumnHelper<WebflowTable>();
		const columnDefs: ColumnDef<any, any>[] = [
			columnHelper.accessor((row: WebflowTable) => row.site_url, {
				id: 'siteURL',
				header: () => (<div style={{textAlign: "center"}}>Site URL</div>),
				cell: props => {
					const siteURL = props.row.original.site_url;
					if (siteURL.length <= 20){
						return (
							<div>
								<NavLink to={props.row.original.site_url} target="_blank">
									{props.row.original.site_url}
								</NavLink>
							</div>
						)
					} else {
						return (
							<div>
								<NavLink to={props.row.original.site_url} target="_blank">
									{props.row.original.site_url.substring(0, 17) + "..."}
								</NavLink>
							</div>
						)
					}
				},
				enableGlobalFilter: true,
				meta: {
					align: 'center'
				}
			}),
			columnHelper.accessor((row: WebflowTable) => row.site_id, {
				id: 'siteID',
				header: "Site ID",
				cell: info => info.getValue().toString(),
				enableGlobalFilter: true,
			}),
			columnHelper.accessor((row: WebflowTable) => row.collection_id, {
				id: 'collectionID',
				header: () => (<div style={{textAlign: "center"}}>Collection ID</div>),
				cell: info => info.getValue().toString(),
				enableGlobalFilter: true,
				meta: {
					align: 'center'
				}
			}),
			columnHelper.display({
				id: "remove",
				header: () => "Remove",
				cell: props => {
					return (
						<AbunButton className={"integration-button"} type={"danger"} clickHandler={() => {
							setIntegrationUniqueID(props.row.original.collection_id);
							setIntegrationViewSitesModal({active: false, integration: "", modelWidth: ""});
							setIntegrationDeleteModal({active: true, integration: "webflow"});
						}}>
							<Icon iconName={"trash"} additionalClasses={["icon-white"]}/>&nbsp;&nbsp;Remove
						</AbunButton>
					)
				}
			})
		]
		setTableColumnDefs(columnDefs);

		// Fetch & set the table data
		getWebflowSites().then(res => {
			setTableDataLoading(false);
			setTableData(res.data.table_data);
		}).catch(error => {
			setTableDataLoading(false);
			console.log(error);
		})

	}

	function setUpTableForWix(){
		interface WixTable {
			site_id: string,
			site_url: string,
		}

		// -------------------------- TABLE SETUP --------------------------
		setTableDataLoading(true);

		const columnHelper = createColumnHelper<WixTable>();
		const columnDefs: ColumnDef<any, any>[] = [
			columnHelper.accessor((row: WixTable) => row.site_url, {
				id: 'siteURL',
				header: () => (<div style={{textAlign: "center"}}>Site URL</div>),
				cell: props => {
					const siteURL = props.row.original.site_url;
					if (!siteURL){
						return (
							<div>––</div>
						)
					} else if (siteURL.length <= 20){
						return (
							<div>
								<NavLink to={props.row.original.site_url} target="_blank">
									{props.row.original.site_url}
								</NavLink>
							</div>
						)
					} else {
						return (
							<div>
								<NavLink to={props.row.original.site_url} target="_blank">
									{props.row.original.site_url.substring(0, 17) + "..."}
								</NavLink>
							</div>
						)
					}
				},
				enableGlobalFilter: true,
				meta: {
					align: 'center'
				}
			}),
			columnHelper.accessor((row: WixTable) => row.site_id, {
				id: 'siteID',
				header: "Site ID",
				cell: info => info.getValue().toString(),
				enableGlobalFilter: true,
			}),
			columnHelper.display({
				id: "remove",
				header: () => "Remove",
				cell: props => {
					return (
						<AbunButton className={"integration-button"} type={"danger"} clickHandler={() => {
							setIntegrationUniqueID(props.row.original.site_id);
							setIntegrationViewSitesModal({active: false, integration: "", modelWidth: ""});
							setIntegrationDeleteModal({active: true, integration: "wix"});
						}}>
							<Icon iconName={"trash"} additionalClasses={["icon-white"]}/>&nbsp;&nbsp;Remove
						</AbunButton>
					)
				}
			})
		]
		setTableColumnDefs(columnDefs);

		// Fetch & set the table data
		getWixSites().then(res => {
			setTableDataLoading(false);
			setTableData(res.data.table_data);
		}).catch(error => {
			setTableDataLoading(false);
			console.log(error);
		})

	}

	function setUpTableForWordpress(){
		interface Wordpress {
			site_url: string,
		}

		// -------------------------- TABLE SETUP --------------------------
		setTableDataLoading(true);

		const columnHelper = createColumnHelper<Wordpress>();
		const columnDefs: ColumnDef<any, any>[] = [
			columnHelper.accessor((row: Wordpress) => row.site_url, {
				id: 'siteURL',
				header: () => (<div style={{textAlign: "center"}}>Site URL</div>),
				cell: props => {
					const siteURL = props.row.original.site_url;
					if (siteURL.length <= 20){
						return (
							<div>
								<NavLink to={props.row.original.site_url} target="_blank">
									{props.row.original.site_url}
								</NavLink>
							</div>
						)
					} else {
						return (
							<div>
								<NavLink to={props.row.original.site_url} target="_blank">
									{props.row.original.site_url}
								</NavLink>
							</div>
						)
					}
				},
				enableGlobalFilter: true,
				meta: {
					align: 'center'
				}
			}),
			columnHelper.display({
				id: "remove",
				header: () => "Remove",
				cell: props => {
					return (
						<AbunButton className={"integration-button"} type={"danger"} clickHandler={() => {
							setIntegrationUniqueID(props.row.original.site_url);
							setIntegrationViewSitesModal({active: false, integration: "", modelWidth: ""});
							setIntegrationDeleteModal({active: true, integration: "wordpress"});
						}}>
							<Icon iconName={"trash"} additionalClasses={["icon-white"]}/>&nbsp;&nbsp;Remove
						</AbunButton>
					)
				}
			})
		]
		setTableColumnDefs(columnDefs);

		// Fetch & set the table data
		getWordpressSites().then(res => {
			setTableDataLoading(false);
			setTableData(res.data.table_data);
		}).catch(error => {
			setTableDataLoading(false);
			console.log(error);
		})

	}

	return (
		<>
			{/* ============================================================================================= */}
			{/* ------------------------------------- Integration Modal ------------------------------------- */}
			{/* ============================================================================================= */}
			<AbunModal active={integrationModalActive}
				headerText={"Abun Integrations"}
				closeable={true}
				hideModal={() => {
					setIntegrationModalActive(false);
				}}>
				{integrationModalContent()}
			</AbunModal>

			{/* ==================================================================================================== */}
			{/* ------------------------------------- Integration Delete Alert ------------------------------------- */}
			{/* ==================================================================================================== */}
			<AbunModal active={integrationDeleteModal.active}
				headerText={"Abun Integrations"}
				closeable={false}
				hideModal={() => {
					setIntegrationModalActive(false);
				}}>
				<div>
					{(allArticlesIntegrations.includes(integrationDeleteModal.integration)) &&
						<>
							<h4 className={"is-size-4 has-text-centered"}>Confirm Deleting Integration?</h4>
							<p className={"has-text-centered mt-4"}>
								This will immediately remove the integration from your abun account and stop your Content Automation & Schedule Article feature.
								Any pending Content Automation & Scheduled Article will not be published.
							</p>
							<div className={"mt-6 has-text-centered is-flex is-justify-content-center is-align-items-center"}>
								<AbunButton type={"danger"} disabled={removeIntegrationMut.isLoading} clickHandler={() => {
									removeAllIntegrations();
								}}>
									Yes, Delete
								</AbunButton>
								<AbunButton type={"primary"} className={"ml-4"} clickHandler={() => {
									setIntegrationDeleteModal({ active: false, integration: "" });
								}}>
									Cancel
								</AbunButton>
							</div>
						</>
					}
					{(integrationDeleteModal.integration === "google-search-console" || integrationDeleteModal.integration === "google-analytics" || integrationDeleteModal.integration === "google-drive") &&
						<>
							<h4 className={"is-size-4 has-text-centered"}>Confirm Deleting Integration?</h4>
							<p className={"has-text-centered mt-4"}>
								This will immediately remove the integration from your abun account.
							</p>
							<div className={"mt-6 has-text-centered is-flex is-justify-content-center is-align-items-center"}>
								<AbunButton type={"danger"} disabled={removeIntegrationMut.isLoading} clickHandler={() => {
									removeAllIntegrations();
								}}>
									Yes, Delete
								</AbunButton>
								<AbunButton type={"primary"} className={"ml-4"} clickHandler={() => {
									setIntegrationDeleteModal({ active: false, integration: "" });
								}}>
									Cancel
								</AbunButton>
							</div>
						</>
					}
				</div>
			</AbunModal>

			{/* ==================================================================================================== */}
			{/* ---------------------------------- Integration View Sites Alert ------------------------------------ */}
			{/* ==================================================================================================== */}
			<AbunModal active={integrationViewSitesModal.active}
								 headerText={`${integrationViewSitesModal.integration} Sites`}
								 closeable={true}
								 modelWidth={integrationViewSitesModal.modelWidth}
								 hideModal={() => {
									 setIntegrationViewSitesModal({active: false, integration: "", modelWidth: ""});
								 }}>
				{tableDataLoading ? 
				<div>
					<h4 className={"is-size-5 has-text-centered"}>
					Loading...&nbsp;&nbsp;<Icon iconName="spinner" />
					</h4>
				</div> :
				<AbunTable
					buttons={
						[
							{
								text: "Connect More Sites",
								type: "primary",
								clickHandler: () => {
									setIntegrationViewSitesModal({active: false, integration: "", modelWidth: ""});
									if (integrationViewSitesModal.integration === "Webflow"){
										launchWebflowIntegrationWizard();
									} else if (integrationViewSitesModal.integration === "Wix") {
										launchWixIntegrationWizard();
									} else {
										launchWordpressIntegrationWizard();
									}
								}
							}
						]
					}
					tableContentName={`Connected ${integrationViewSitesModal.integration} Sites`}
					tableData={tableData}
					columnDefs={tableColumnDefs}
					pageSizes={[5, 10, 15]}
					initialPageSize={5}
					noDataText={`No ${integrationViewSitesModal.integration} site is connected.`}/>
				}
			</AbunModal>


			{/* ---------------------------- AVAILABLE INTEGRATIONS ---------------------------- */}
			<div className={"card w-100"}>
				<div className={"card-header"}>
					<p className={"card-header-title"}>Available Integrations</p>
				</div>
				<div className={"card-content"}>
					<div className={"content"}>
						<ul className={"integrations-list"}>
							{/* ************************ WORDPRESS ************************ */}
							<li className={"integration-list-item"}>
								<div className={"is-flex is-flex-direction-row"}>
									<img src={wordpressLogo} className={"integration-item-logo"} alt="wordpress logo"/>
									{integrationExists("wordpress") ?
										<p className={"integration-item-name integration-item-installed"}>
											Wordpress
										</p> :
										<p className={`integration-item-name integration-item-not-installed ${props.currentPlanName === "Trial" && "integration-item-not-installed-trial-plan"}`}>
											Wordpress
											{props.currentPlanName === "Trial" &&
												<AbunButton type="primary" style={{
													marginLeft: "14.3rem",
													marginTop:"-1.4rem",
													height: "1.8rem",
													borderRadius: "15px",
													fontSize: "12px"
												}} clickHandler={() => {
													navigate("/manage-subscription");
												}}>
													Upgrade ⚡
												</AbunButton>
											}
										</p>}
								</div>
								{integrationExists("wordpress") ?
									<AbunButton className={"integration-button"} type={"primary"} clickHandler={() => {
										setUpTableForWordpress();
										setIntegrationViewSitesModal({active: true, integration: "Wordpress", modelWidth: ""});
									}}>
										View Sites
									</AbunButton> :
									<div data-tooltip-id="connect-wordpress" data-tooltip-content={!baseData.user_verified ? "Verify email to Connect Wordpress" :
										"Upgrade Your Plan."}>
										<AbunButton
											className={"integration-button"}
											disabled={!baseData.user_verified ? true : false || props.currentPlanName === "Trial"}
											type={"primary"} clickHandler={() => {
												launchWordpressIntegrationWizard();
											}}>
											Connect Wordpress
										</AbunButton>
										{(!baseData.user_verified || props.currentPlanName === "Trial") && <Tooltip id="connect-wordpress" />}
									</div>
								}
							</li>
							{/* ************************ WEBFLOW ************************ */}
							<li className={"integration-list-item"}>
								<div className={"is-flex is-flex-direction-row"}>
									<img src={webflowLogo} className={"integration-item-logo"} alt="Webflow logo"/>
									{integrationExists("webflow") ?
										<p className={"integration-item-name integration-item-installed"}>
											Webflow
										</p> :
										<p className={`integration-item-name integration-item-not-installed ${props.currentPlanName === "Trial" && "integration-item-not-installed-trial-plan"}`}>
											Webflow
											{props.currentPlanName === "Trial" &&
												<AbunButton type="primary" style={{
													marginLeft: "14.3rem",
													marginTop:"-1.4rem",
													height: "1.8rem",
													borderRadius: "15px",
													fontSize: "12px"
												}} clickHandler={() => {
													navigate("/manage-subscription");
												}}>
													Upgrade ⚡
												</AbunButton>
											}
										</p>}
								</div>
								{integrationExists("webflow") ?
								<AbunButton className={"integration-button"} type={"primary"} clickHandler={() => {
									setUpTableForWebflow();
									setIntegrationViewSitesModal({active: true, integration: "Webflow", modelWidth: "750px"});
								}}>
									View Sites
								</AbunButton> :
								<div data-tooltip-id="connect-webflow" data-tooltip-content={!baseData.user_verified ? "Verify email to Connect Wordpress" :
									"Upgrade Your Plan."}>
									<AbunButton className={"integration-button"} type={"primary"}
									disabled={!baseData.user_verified ? true : false || integrationProcessing.name !== "" || props.currentPlanName === "Trial"}
									clickHandler={() => {
										launchWebflowIntegrationWizard();
										// startWebflowIntegration();
									}}>
										{integrationProcessing.name === "webflow" ? "Connecting..." : "Connect Webflow"}
									</AbunButton>
									{(!baseData.user_verified || props.currentPlanName === "Trial") && <Tooltip id="connect-webflow" /> }
								</div>}
							</li>
							{/* ************************ WIX ************************ */}
							<li className={"integration-list-item"}>
								<div className={"is-flex is-flex-direction-row"}>
									<img src={wixLogo} className={"integration-item-logo"} alt="WIX logo"/>
									{integrationExists("wix") ?
										<p className={"integration-item-name integration-item-installed"}>
											WIX
										</p> :
										<p className={`integration-item-name integration-item-not-installed ${props.currentPlanName === "Trial" && "integration-item-not-installed-trial-plan"}`}>
											WIX
											{props.currentPlanName === "Trial" &&
												<AbunButton type="primary" style={{
													marginLeft: "14.3rem",
													marginTop: "-1.4rem",
													height: "1.8rem",
													borderRadius: "15px",
													fontSize: "12px"
												}} clickHandler={() => {
													navigate("/manage-subscription");
												}}>
													Upgrade ⚡
												</AbunButton>
											}
										</p>}
								</div>
								{integrationExists("wix") ?
								<AbunButton className={"integration-button"} type={"primary"} clickHandler={() => {
									setUpTableForWix();
									setIntegrationViewSitesModal({active: true, integration: "Wix", modelWidth: "600px"});
								}}>
									View Sites
								</AbunButton> :
									<div data-tooltip-id="connect-wix" data-tooltip-content={!baseData.user_verified ? "Verify email to Connect WIX" :
										"Upgrade Your Plan."}>
										<AbunButton className={"integration-button"} type={"primary"}
											disabled={!baseData.user_verified ? true : false || integrationProcessing.name !== "" || props.currentPlanName === "Trial"}
											clickHandler={() => {
												launchWixIntegrationWizard();
											}}>
											{integrationProcessing.name === "wix" ? "Connecting..." : "Connect WIX"}
										</AbunButton>
										{(!baseData.user_verified || props.currentPlanName === "Trial") && <Tooltip id="connect-wix" />}
									</div>}
							</li>
							{/* ************************ GOOGLE SEARCH CONSOLE ************************ */}
							<li className={"integration-list-item"}>
								<div className={"is-flex is-flex-direction-row"}>
									<img src={googleSearchConsoleLogo} className={"integration-item-logo"} alt="Google search console logo" />
									{hasGoogleSearchConsoleIntegration ?
										<p className={"integration-item-name integration-item-installed"}>Google Search Console</p> :
										<p className={`integration-item-name integration-item-not-installed ${props.currentPlanName === "Trial" && "integration-item-not-installed-trial-plan"}`}>
											Google Search Console
											{props.currentPlanName === "Trial" &&
												<AbunButton type="primary" style={{
													marginLeft: "14.3rem",
													marginTop: "-1.4rem",
													height: "1.8rem",
													borderRadius: "15px",
													fontSize: "12px"
												}} clickHandler={() => {
													navigate("/manage-subscription");
												}}>
													Upgrade ⚡
												</AbunButton>
											}
										</p>}
								</div>
								{hasGoogleSearchConsoleIntegration ?
									<AbunButton className={"integration-button"} type={"light"} clickHandler={() => {
										setIntegrationDeleteModal({ active: true, integration: "google-search-console" });
									}}>
										<Icon iconName={"trash"} additionalClasses={["icon-grey"]} />&nbsp;&nbsp;Remove
									</AbunButton> :
									<AbunButton className={"integration-button"} type={"primary"} disabled={props.currentPlanName === "Trial"}
										clickHandler={() => {
											googleIntegration("google-search-console");
										}}>
										{GSCIntegrationProcessing ? "Connecting..." : "Connect GSC"}
									</AbunButton>}
							</li>
							{/* ************************ GOOGLE ANALYTICS ************************ */}
							{/* <li className={"integration-list-item"}>
								<div className={"is-flex is-flex-direction-row"}>
									<img src={googleAnalyticsLogo} className={"integration-item-logo"} alt="Google analytics logo"/>
									{hasGoogleAnalyticsIntegration ?
										<p className={"integration-item-name integration-item-installed"}>Google Analytics</p> :
										<p className={"integration-item-name integration-item-not-installed"}>Google Analytics</p>}
								</div>
								{hasGoogleAnalyticsIntegration ?
									<AbunButton className={"integration-button"} type={"light"} clickHandler={() => {
										setIntegrationDeleteModal({active: true, integration: "google-analytics"});
									}}>
										<Icon iconName={"trash"} additionalClasses={["icon-grey"]}/>&nbsp;&nbsp;Remove
									</AbunButton> :
									<AbunButton className={"integration-button"} type={"primary"} disabled={GAIntegrationProcessing || disableAllGoogleIntegrationButtons} clickHandler={() => {
										googleIntegration("google-analytics");
									}}>
										{GAIntegrationProcessing ? "Connecting..." : "Connect GA"}
									</AbunButton>}
							</li> */}
							{/* ************************ GOOGLE DRIVE ************************ */}
							{/* <li className={"integration-list-item"}>
								<div className={"is-flex is-flex-direction-row"}>
									<img src={googleDriveLogo} className={"integration-item-logo"} alt="Google drive logo"/>
									{hasGoogleDriveIntegration ?
										<p className={"integration-item-name integration-item-installed"}>Google Drive</p> :
										<p className={"integration-item-name integration-item-not-installed"}>Google Drive</p>}
								</div>
								{hasGoogleDriveIntegration ?
									<AbunButton className={"integration-button"} type={"light"} clickHandler={() => {
										setIntegrationDeleteModal({active: true, integration: "google-drive"});
									}}>
										<Icon iconName={"trash"} additionalClasses={["icon-grey"]}/>&nbsp;&nbsp;Remove
									</AbunButton> :
									<AbunButton className={"integration-button"} type={"primary"} disabled={GDIntegrationProcessing || disableAllGoogleIntegrationButtons} clickHandler={() => {
										googleIntegration("google-drive");
									}}>
										{GDIntegrationProcessing ? "Connecting..." : "Connect GD"}
									</AbunButton>}
							</li> */}
						</ul>
					</div>
				</div>
			</div>

			{/* ---------------------------- CONTENT SCHEDULING OPTIONS ---------------------------- */}
			{/* 
			<div className={"card w-100 mt-5"}>
				<header className={"card-header"}>
					<p className={"card-header-title"}>Content Scheduling</p>
				</header>
				<div className={"card-content settings-card"}>
					<div className={"content"}>
						<div className={"settings-section"}>
							<h5 className={"settings-section-title"}>
								Automatically publish generated blogs on your website.
							</h5>
							<p className={"settings-radio-item-description"}>
								<b>Note:</b> Requires an active integration.
							</p>
							<div className="field mt-4">
								{autoPostButtonRender()}
							</div>
						</div>
					</div>
				</div>
			</div>
			 */}
		</>
	)
}
