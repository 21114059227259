import { MutableRefObject, useEffect, useRef, useState, useMemo } from "react";
import { useMutation, useQuery } from '@tanstack/react-query'
import { RowData, RowModel } from "@tanstack/react-table";
import Icon from "../../components/Icon/Icon";
import { Tooltip } from "react-tooltip";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import LinkButton from "../../components/LinkButton/LinkButton";
import GenericButton from "../../components/GenericButton/GenericButton";
import AbunTable, { IndeterminateCheckbox } from "../../components/AbunTable/AbunTable";
import {
	generateV2ArticleMutation,
	loadArticleTitlesQuery,
	postArticleMutation,
	generateBulkV2ArticleMutation,
	archiveBulkArticleMutation,
	postBulkArticleMutation,
} from "../../utils/api";
import AbunModal from "../../components/AbunModal/AbunModal";
import loadingIcon from "../../assets/images/loadingIcon.webp"
import SuccessAlert from "../../components/SuccessAlert/SuccessAlert";
import ErrorAlert from "../../components/ErrorAlert/ErrorAlert";
import wordpressIconSuccess from "../../assets/images/wordpress-logo.png";
import webflowIconSuccess from "../../assets/images/webflow-logo.png";
import wordpressIconPrimary from "../../assets/images/wordpress-logo-primary.png";
import webflowIconPrimary from "../../assets/images/webflow-logo-primary.png";
import wixIconPrimary from "../../assets/images/wix-logo-primary.png";
import wixIconSuccess from "../../assets/images/wix-logo-success.png";
import articleIntegrationIcon from "../../assets/images/article-integration-logo.png"


import "./Articles.scss";
import { pageURL } from "../routes";
import { Link, useLoaderData, useNavigate} from "react-router-dom";




interface ArticlesPageData {
	user_verified: boolean
	stats_title_count?: number
	stats_keywords_count?: number
	stats_competitors_count?: number
	stats_traffic?: Array<number>
	has_active_website: boolean
}

interface ArticlesDataProps {
	userVerified: boolean
	successAlertRef: MutableRefObject<any>
	failAlertRef: MutableRefObject<any>
	isLoading: boolean
	error: any
	refetch: any
	data: any
	setClosablePopup: (value: boolean) => void
}

type ArticleTitle = {
	articleUID: string
	articleTitle: string
	keyword: string
	keywordTraffic: number | null
	internalLinks: number | null
	externalLinks: number | null
	images: number | null
	wordCount: number | null
	isProcessing: boolean
	isGenerated: boolean
	isPosted: boolean
	isFailed: boolean
	isArchived: boolean
	postLink: string
	isUserAdded: boolean
	postedTo: string
	createdOn: string
	generatedOn: string
	keywordHash:string
	KeywordProjectId:string
	
}

export type AllIntegrations = {
	integrationName: string
	integrationUniqueID: string
}

interface InitialSortingState {
	id: string;
	desc: boolean;
}



const integrationLogoPrimary = {
	"wordpress": wordpressIconPrimary,
	"webflow": webflowIconPrimary,
	"wix": wixIconPrimary
}

const integrationLogoSuccess = {
	"wordpress": wordpressIconSuccess,
	"webflow": webflowIconSuccess,
	"wix": wixIconSuccess
}

export default function ShowV2Articles() {
	const [closablePopup, setClosablePopup] = useState(false);

	// ----------------------- PAGE DATA -----------------------
	const pageData = useLoaderData() as ArticlesPageData;

	// ----------------------- EFFECTS -----------------------
	const { isLoading, error, data, refetch } = useQuery(loadArticleTitlesQuery());

	// ----------------------- REFS -----------------------
	const successAlertRef = useRef<any>(null);
	const failAlertRef = useRef<any>(null);

	useEffect(() => {
		document.title = "Articles | Abun"
	}, []);

	return (
		<>
			{
				closablePopup &&
				<div className={"tipcard card is-flex w-100 is-align-items-center is-justify-content-space-between is-flex-direction-column mb-4"}>
					<img src="https://res.cloudinary.com/diaiivikl/image/upload/v1722685153/handy-ezgif.com-gif-to-webp-converter_fq22f0.webp" alt="printer" />
					<div className={"tipcard-content is-flex is-justify-content-center is-align-items-center is-flex-direction-column"}>
						<h2 className={"is-size-4"}>Creating Articles is Easy Peasy!</h2>
						<h6>
							Find Keywords &gt; Choose Keywords to Write Articles for &gt; Choose Relevant Titles &gt; Generate Articles 🎉
						</h6>
						<div className={"tipcard-btns"}>
							<Link className={"button is-primary mt-2"} type="link" to={pageURL['keywordResearch']}>Do Keyword Research</Link>
							<Link className={"button is-primary mt-2"} type="link" to={pageURL['tutorials']}>Watch Video Tutorial</Link>
						</div>
					</div>
					<div className={"close-popup"} onClick={() => setClosablePopup(false)}>
						x
					</div>
				</div>
			}

			<div className="card mt-4 articles-table-card">
				<div className="card-content">
					<div className="content is-flex is-flex-direction-column">
						<ArticlesData userVerified={pageData.user_verified}
							isLoading={isLoading}
							error={error}
							data={data}
							refetch={refetch}
							successAlertRef={successAlertRef}
							failAlertRef={failAlertRef}
							setClosablePopup={setClosablePopup}
						/>
					</div>
				</div>
			</div>
			<SuccessAlert ref={successAlertRef} />
			<ErrorAlert ref={failAlertRef} />
		</>
	)
}

function ArticlesData(props: ArticlesDataProps) {
	// ----------------------- REFS -----------------------
	const navigate = useNavigate();

	// ----------------------- NON STATE CONSTANTS -----------------------
	const pageSizes = [5, 10, 15, 30, 50, 100, 500];
	const { isLoading, error, data, refetch} = props;

	// ----------------------- STATES -----------------------
	const [tableData, setTableData] = useState<Array<ArticleTitle>>([]);
	const [requestModalActive, setRequestModalActive] = useState(false);
	const [integrationModalActive, setIntegrationModalActive] = useState(false);
	const [modalText, setModalText] = useState("");
	const [selectedRows, setSelectedRows] = useState<RowModel<RowData>>();
	const [initialSortingState, setInitialSortingState] = useState<InitialSortingState[]>([{
		id: "wordCount", desc: true
	}])
	const [bulkActionsEnabled, setBulkActionsEnabled] = useState(true);
	const [searchText, setSearchText] = useState("");
	const [selectedTab, setSelectedTab] = useState("Generated Articles");
	const allBulkOptions = useMemo(() => [
		{
			text: "Publish",
		},
		{
			text: "Archive",
		},
		{
			text: "Unarchive",
		},
	], []);
	const [availableBulkActions, setAvailableBulkActions] = useState(allBulkOptions);
	const [openDropdown, setOpenDropdown] = useState("");
	const [selectedIntegration, setSelectedIntegration] = useState("");
	const [selectedIntegrationUniqueID, setSelectedIntegrationUniqueID] = useState("");
	const [integrationWithUniqueID, setIntegrationWithUniqueID] = useState<Array<AllIntegrations>>([]);

	// ----------------------- EFFECTS -----------------------
	const generateArticle = useMutation(generateV2ArticleMutation);
	const postArticle = useMutation(postArticleMutation);
	const postSelectedArticles = useMutation(postBulkArticleMutation);
	const generateSelectedArticles = useMutation(generateBulkV2ArticleMutation);
	const archiveSelectedArticles = useMutation(archiveBulkArticleMutation);

	useEffect(() => {
		if (data) {
			// sorting the data so that recently created articles are shown first
			let sortedData: Array<ArticleTitle> = ((data as any)['data']['title_data']).sort((a: ArticleTitle, b: ArticleTitle) => {
				return new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime();
			});

			//generatedOn
			sortedData = sortedData.sort((a: ArticleTitle, b: ArticleTitle) => {
				// keep the generated articles at the top
				if (a.isGenerated && !b.isGenerated) {
					return -1;
				} else if (!a.isGenerated && b.isGenerated) {
					return 1;
				} else if (a.isGenerated && b.isGenerated) {
					return new Date(b.generatedOn).getTime() - new Date(a.generatedOn).getTime();
				}
				// keep the processing articles at the top
				if (a.isProcessing && !b.isProcessing) {
					return -1;
				} else if (!a.isProcessing && b.isProcessing) {
					return 1;
				}
				// keep the failed articles at the top
				if (a.isFailed && !b.isFailed) {
					return -1;
				} else if (!a.isFailed && b.isFailed) {
					return 1;
				} else {
					return 0;
				}
			});

			setSelectedIntegration((data as any)['data']['all_integrations'].length === 0 ? "" : (data as any)['data']['all_integrations'][0]);
			setIntegrationWithUniqueID((data as any)['data']['all_integrations_with_unique_id']);

			// hide the table column according to the selected tab
			const tab_to_hide = document.querySelector("#article-titles-table .hide-column-child")?.parentElement || document.querySelector("#article-titles-table .hide-column");

			// filter the data according to the selected tab
			if (selectedTab === "Generated Articles") {
				const removeOptions = ["Unarchive"];
				const newBulkOptions = allBulkOptions.filter(option => !removeOptions.includes(option.text));
				setAvailableBulkActions(newBulkOptions);
				setBulkActionsEnabled(true);

				//data.title_data[0].isGenerated
				while (tab_to_hide && tab_to_hide.classList.contains("hide-column")) {
					tab_to_hide.classList.remove("hide-column");
				}
				setTableData(sortedData.filter((article: ArticleTitle) => {
					return !article.isArchived && !article.isPosted && (article.isGenerated || article.isProcessing || article.isFailed);
				}));
			} else if (selectedTab === "Published Articles") {
				const removeOptions = ["Unarchive", "Publish"];
				const newBulkOptions = allBulkOptions.filter(option => !removeOptions.includes(option.text));
				setAvailableBulkActions(newBulkOptions);
				setBulkActionsEnabled(true);

				while (tab_to_hide && tab_to_hide.classList.contains("hide-column")) {
					tab_to_hide.classList.remove("hide-column");
				}
				setTableData(sortedData.filter((article: ArticleTitle) => {
					return article.isPosted && !article.isArchived;
				}));
			} else if (selectedTab === "Archived Articles") {
				setAvailableBulkActions([]);
				setBulkActionsEnabled(false);

				while (tab_to_hide && tab_to_hide.classList.contains("hide-column")) {
					tab_to_hide.classList.remove("hide-column");
				}
				setTableData(sortedData.filter((article: ArticleTitle) => {
					return article.isArchived;
				}));
			} else if (selectedTab === "Scheduled Articles") {
				setAvailableBulkActions([]);
				setBulkActionsEnabled(false);

				while (tab_to_hide && tab_to_hide.classList.contains("hide-column")) {
					tab_to_hide.classList.remove("hide-column");
				}
				setTableData((data as any)['data']['scheduled_articles_data'].filter((article: ArticleTitle) => {
					return !article.isArchived;
				}));
			}
		}
	}, [allBulkOptions, data, selectedTab]);


	useEffect(() => {
		if (window.location.search) {
			const searchParams = new URLSearchParams(window.location.search);
			const title = searchParams.get("title");
			setSearchText(title?.toLowerCase() || "");
		}
	}, []);

	// =========================================================
	// ----------------------- MAIN CODE -----------------------
	// =========================================================
	function generateArticleHandler(articleUID: string) {
		setModalText("Processing request. Please wait...");
		setRequestModalActive(true);
		generateArticle.mutate(articleUID, {
			onSuccess: (data) => {
				// only 200 response is handled here
				setRequestModalActive(false);
				let responseData = (data as any)["data"];

				if (responseData["status"] === "sent_for_processing") {
					navigate(`/articles/edit/${articleUID}/`);

				} else if (responseData["status"] === "rejected") {

					if (responseData["reason"] === "max_limit_reached") {
						props.failAlertRef.current?.show("Article generation request failed. " +
							"You have reached your max article generation limit for the month.");

					} else {
						props.failAlertRef.current?.show(
							`Article generation request failed. Error ID: ${responseData["reason"]}`
						);
					}

				} else {
					props.failAlertRef.current?.show(
						`Article generation request returned unknown 
						status ${responseData["status"]}. Please contact us if there's any issue.`
					);
				}
				setInitialSortingState(initialSortingState);
			},
			onError: (error: Error) => {
				console.error(error);
				setRequestModalActive(false);
				props.failAlertRef.current?.show(`Article generation request failed. Please try again later`)
				setTimeout(() => {
					props.failAlertRef.current?.close();
				}, 5000);
			}
		});
	}

	function postToBlogHandler(articleUID: string) {
		props.failAlertRef.current?.close();
		props.successAlertRef.current?.close();

		setModalText("Posting to your website blog. Please wait...");
		setRequestModalActive(true);
		postArticle.mutate({
			articleUID: articleUID,
			selectedIntegration: selectedIntegration,
			selectedIntegrationUniqueID: selectedIntegrationUniqueID
		}, {
			onSuccess: () => {
				setRequestModalActive(false);
				refetch().then(() => {
					props.successAlertRef.current?.show(`Article ${articleUID} was posted to your site successfully!`);
				});
			},
			onError: () => {
				setRequestModalActive(false);
				props.failAlertRef.current?.show(
					"Oops! Something went wrong. Check your Email for more details or contact us if the issue persists."
				);
			}
		})
	}

	function setIntegrationAndHideDropDownContent(integrate: string) {
		setSelectedIntegration(integrate);
		setOpenDropdown("");
	}

	function getIntegrationName(integrate: string | undefined){
		if (!integrate){
			return "wordpress";
		} else {
			if (integrate.includes("wordpress")){
				return "wordpress";
			} else if (integrate.includes("webflow")){
				return "webflow";
			} else {
				return "wix";
			}
		}
	}

	function truncateSiteDomain(domain: string){
		if (domain.includes("Webflow")){
			domain = domain.replace(new RegExp("Webflow - ", 'g'), '');
		} else if (domain.includes("Wix")){
			domain = domain.replace(new RegExp("Wix - ", 'g'), '');
		} else {
			domain = domain.replace(new RegExp("Wordpress - ", 'g'), '');
		}

		if (domain.length <= 20){
			return domain;
		} else {
			domain = domain.substring(0, 17) + "...";
		}

		return domain;
	}

	if (error) {
		// Called when all retries are exhausted.
		return <p>Error!</p>

	} else if (isLoading) {
		return (
			<p style={{ textAlign: "center", fontSize: "1.3rem" }}>
				Loading Data...<Icon iconName={"spinner"} marginClass={"ml-5"} />
			</p>
		)

	} else {
		// ================== Generate table data and render AbunTable component ==================
		const columnHelper = createColumnHelper<ArticleTitle>();

		const columnDefs: ColumnDef<any, any>[] = [
			columnHelper.accessor((row: ArticleTitle) => row.articleTitle, {
				id: 'articleTitle',
				header: "Article Title",
				cell: cellProps => {
					let processing: boolean = cellProps.row.original.isProcessing;
					let generated: boolean = cellProps.row.original.isGenerated;
					let failed: boolean = cellProps.row.original.isFailed;
					if (generated) {
						return (
							<>
								<Link to={`/articles/edit/${cellProps.row.original.articleUID}/`}
									style={{ textDecoration: "none", color: "#000" }}>
									{cellProps.row.original.articleTitle}
								</Link>
							</>
						)
					} else if (processing) {
						return cellProps.row.original.articleTitle
					} else if (failed) {
						return cellProps.row.original.articleTitle
					} else {
						return cellProps.row.original.articleTitle
					}
				},
				enableGlobalFilter: true,
				enableSorting: false,
			}),
			columnHelper.accessor((row: ArticleTitle) => row.keyword, {
				id: 'keyword',
				header: "Keyword [Traffic]",
				cell: info => (
					<span
					  onClick={() => {
						const row = info.row.original; 
						navigate(`/keyword-project/${row.KeywordProjectId}/titles/${row.keywordHash}`)
					}}
					>
					  {info.getValue()}
					  {info.row.original.keywordTraffic && ` [${info.row.original.keywordTraffic}]`}
					</span>
				  ),
				enableGlobalFilter: false,
			}),
			columnHelper.accessor((row: ArticleTitle) => row.wordCount, {
				id: 'wordCount',
				header: () => {
					return "Words"
				},
				cell: (info) => {
					if (info.getValue() === null || info.getValue() === 0) {
						return "---";
					} else {
						return info.getValue();
					}
				},
				enableGlobalFilter: false,
				meta: {
					align: 'center'
				}
			}),
			//adding created on column in generated word page
			columnHelper.accessor((row: ArticleTitle) => row.createdOn || row.postedTo || row.generatedOn, {
				//changing the ID so it would change the whole context for sorting
				id: selectedTab === "Generated Articles" ? 'createdOn' :
					selectedTab === "Published Articles" ? 'publishedOn' :
					selectedTab === "Archived Articles" ? 'archivedOn' :
					selectedTab === "Scheduled Articles" ? 'scheduledOn' : 'date',
				
				header: () => {
					//Dynamically changing the headers in different tabs
					if (selectedTab === "Generated Articles") return "Created On";
					if (selectedTab === "Published Articles") return "Published On";
					if (selectedTab === "Archived Articles") return "Archived On";
					if (selectedTab === "Scheduled Articles") return "Scheduled On";
					return "Date"; // Fallback header
				},
				cell: (info) => {
					const createdDate = info.getValue();
					if (!createdDate) {
						return "---";
					}
		//defining date showing context
					const getRelativeTime = (dateString: string) => {
						const createdDateObj = new Date(dateString);
						const now = new Date();
						const timeDiff = Math.abs(now.getTime() - createdDateObj.getTime());
		
						const seconds = Math.floor(timeDiff / 1000);
						const minutes = Math.floor(seconds / 60);
						const hours = Math.floor(minutes / 60);
						const days = Math.floor(hours / 24);
		
						if (days > 30) {
							const day = createdDateObj.getDate();
							const month = createdDateObj.toLocaleString('default', { month: 'short' });
							const year = createdDateObj.getFullYear().toString().slice(-2);
							return `${day} ${month}, ${year}`;
						}
		
						if (seconds < 60) return "just now";
						if (minutes < 60) return minutes === 1 ? "a minute ago" : `${minutes} minutes ago`;
						if (hours < 24) return hours === 1 ? "an hour ago" : `${hours} hours ago`;
						return days === 1 ? "a day ago" : `${days} days ago`;
					};
		
					return getRelativeTime(createdDate);
				},
				enableGlobalFilter: false,
				meta: {
					align: 'center',
				}
			}),
			
			columnHelper.display({
				id: 'generate_articles',
				header: () => "Action",
				cell: cellProps => {
					let processing: boolean = cellProps.row.original.isProcessing;
					let generated: boolean = cellProps.row.original.isGenerated;
					let failed: boolean = cellProps.row.original.isFailed;
					if (generated) {
						return <LinkButton linkTo={`/articles/edit/${cellProps.row.original.articleUID}/`}
							text={"View"}
							type={"success"}
							width={"100px"}
							outlined={true}
							additionalClassList={["is-small", "more-rounded-borders"]} />
					} else if (processing) {
						return <LinkButton linkTo={`/articles/edit/${cellProps.row.original.articleUID}/`}
							text={"Generating..."}
							type={"primary"}
							width={"100px"}
							outlined={true}
							additionalClassList={["is-small", "more-rounded-borders"]} />
					} else if (failed) {
						return <GenericButton text={"Failed. Retry"}
							type={"danger"}
							width={"100px"}
							disable={generateArticle.isLoading}
							additionalClassList={["is-small", "more-rounded-borders"]}
							clickHandler={() => {
								generateArticleHandler(cellProps.row.original.articleUID);
							}} />
					} else {
						return <div data-tooltip-id="generate-article" data-tooltip-content="Verify email to create article">
							<GenericButton text={"Create"}
								type={"primary"}
								width={"100px"}
								outlined={true}
								disable={generateArticle.isLoading || !props.userVerified}
								additionalClassList={["is-small", "more-rounded-borders"]}
								clickHandler={() => {
									generateArticleHandler(cellProps.row.original.articleUID);
								}} />
							{!props.userVerified && <Tooltip id="generate-article" />}
						</div>
					}
				},
				enableGlobalFilter: false,
				meta: {
					align: 'center',
				}
			}),
			columnHelper.display({
				header: () => {
					if (selectedTab === "Published Articles") {
						return "View"
					} else {
						return "Publish"
					}
				},
				id: 'post_article',
				cell: cellProps => {
					let posted: boolean = cellProps.row.original.isPosted;
					let generated: boolean = cellProps.row.original.isGenerated;
					let postedTo: string = cellProps.row.original.postedTo;
					let archived: boolean = cellProps.row.original.isArchived;
					let articleUID: string = cellProps.row.original.articleUID;
					if (posted) {
						return <>
							<a className={"view-on-blog-link"} href={cellProps.row.original.postLink} data-tooltip-id="my-tooltip" target="_blank" rel="noreferrer" data-tooltip-content="View on Blog" >
								<img className={"image"} src={integrationLogoSuccess[postedTo]} alt={"integration-icon"} />
							</a>
							<Tooltip id="my-tooltip" />
						</>
					} else if (generated) {
						return <>
							<div className="publish-container is-flex is-align-items-center is-justify-content-center">
								<button className={"publish-to-blog-btn is-flex is-align-items-center is-justify-content-center"} data-tooltip-id="publish-to-blog" data-tooltip-content={integrationWithUniqueID.length > 0 ? "Publish to Blog" : "Connect & Publish Now"}
									onClick={() => {
										if (integrationWithUniqueID.length > 0) {
											postToBlogHandler(articleUID);
										} else {
											setIntegrationModalActive(true);
										}
									}}>
									<img className={"image"} src={integrationWithUniqueID.length === 0 ? articleIntegrationIcon : integrationLogoPrimary[getIntegrationName(selectedIntegration)]} alt={"integration-icon"} />
								</button>
								{integrationWithUniqueID.length > 1 && 
									<div className="dropdown">
										<button className={`dropdown-icon ${openDropdown === articleUID ? "rotate" : ""}`} onClick={() => setOpenDropdown(openDropdown === articleUID ? "" : articleUID)}>&#9662;</button>
										<div className={`dropdown-content ${openDropdown === articleUID ? "show" : ""}`}>
											<h6 className={"dropdown-header"}>
												Choose Integration
											</h6>
											{
												integrationWithUniqueID.map((integration, index) => (
													<div key={index} className={"dropdown-item"} onClick={() => {
														setIntegrationAndHideDropDownContent(integration.integrationName);
														setSelectedIntegrationUniqueID(integration.integrationUniqueID);
													}}>
														<input type="radio" name="integration" id={integration.integrationName} checked={selectedIntegration === integration.integrationName} onChange={() => {}} />
														<p>{truncateSiteDomain(integration.integrationName.charAt(0).toUpperCase() + integration.integrationName.slice(1))}</p>
													</div>
												))
											}
										</div>
									</div>
								}
							</div>

							<Tooltip id="publish-to-blog" />
						</>
					} else if (archived) {
						return <GenericButton text={"Unarchive"}
							type={"success"}
							disable={archiveSelectedArticles.isLoading}
							additionalClassList={["is-small", "more-rounded-borders"]}
							clickHandler={() => {
								archiveSelectedArticles.mutate({ articlesUID: [cellProps.row.original.articleUID], archiveType: "unarchive" }, {
									onSuccess: () => {
										refetch().then(() => {
											props.successAlertRef.current?.show("Article was unarchived successfully!");
										});
									},
									onError: () => {
										props.failAlertRef.current?.show("Failed to unarchive article. Please try again after some time.");
									}
								});
							}} />
					} else {
						return <></>
					}
				},
				enableGlobalFilter: false,
				meta: {
					align: 'center'
				}
			})
		]

		if (bulkActionsEnabled){
			// Add checkbox if bulk actions are enabled
			columnDefs.splice(0, 0, columnHelper.accessor((row: ArticleTitle) => row.articleTitle, {
				id: 'checkbox',
				header: ({ table }) => (
					<IndeterminateCheckbox
						{...{
							checked: table.getIsAllRowsSelected(),
							indeterminate: table.getIsSomeRowsSelected(),
							onChange: table.getToggleAllRowsSelectedHandler(),
						}}
					/>
				),
				cell: ({ row }) => (
					<IndeterminateCheckbox
						{...{
							checked: row.getIsSelected(),
							disabled: !row.getCanSelect(),
							indeterminate: row.getIsSomeSelected(),
							onChange: row.getToggleSelectedHandler(),
						}}
						name={"articleSelection"}
						value={row.original.articleUID}
					/>
				),
				enableGlobalFilter: true,
				enableSorting: false,
			}));
		} else {
			// Remove the checkbox if bulk actions are disabled
			columnDefs.splice(0, 0);
		}

		function selectedRowsSetter(rowModel: RowModel<RowData>) {
			setSelectedRows(rowModel);
		}

		return (
			<>
				{/* ------------------------------ INTEGRATION MODAL ------------------------------ */}
				<AbunModal active={integrationModalActive}
					headerText={""}
					closeable={true}
					hideModal={() => {
						setIntegrationModalActive(false)
					}}>
					<div className={"has-text-centered"}>
						<h1 className={"is-size-3"}>Publish articles to your blog page!</h1>
						<p className={"mt-4"}>
							Start publishing articles to your blog page by setting up an Integration for your website.
							You can find it under <b>Settings</b> {">"} <b>Integration & Scheduling</b>
						</p>
						<LinkButton text={"Go to Settings"}
							linkTo={pageURL['settings'] + "?tab=integration"}
							type={"primary"}
							additionalClassList={["mt-5"]} />
					</div>
				</AbunModal>

				{/* ------------------------------ ONGOING REQUEST MODAL ------------------------------ */}
				<AbunModal active={requestModalActive}
					headerText={""}
					closeable={false}
					hideModal={() => {
						setRequestModalActive(false)
					}}>
					<div className={"loadingData w-100 is-flex is-justify-content-center is-align-items-center"}>
						<img className={"image"} src={loadingIcon} alt="loading" />
					</div>
					<p className={"is-size-4 has-text-centered mb-4"}>{modalText}</p>
				</AbunModal>

				{/* ------------------------------ ARTICLE TITLE TABLE ------------------------------ */}
				<AbunTable tableContentName={"Article Titles"}
					id="article-titles-table"
					tableName="Articles"
					searchText={searchText}
					tableData={tableData}
					columnDefs={columnDefs}
					pageSizes={pageSizes}
					initialPageSize={pageSizes[6]}
					enableSorting={true}
					defaultSortingState={initialSortingState}
					handleRowClick={(row) => {
						navigate(`/articles/edit/${row.articleUID}/`);
					}}
					noDataText={
						selectedTab === "Generated Articles"
							? "Generated articles will appear here."
							: selectedTab === "Published Articles"
								? "Published articles will appear here."
								: selectedTab === "Archived Articles"
									? "Archived articles will appear here."
									: "No articles found."
					}
					searchboxPlaceholderText={"Search for Title & Keywords..."}
					rowCheckbox={true}
					selectedRowsSetter={selectedRowsSetter}
					selectedTab={selectedTab}
					filterTabs={[
						{
							name: "Generated Articles",
							onClick: () => {
								// if (selectedTab === "Generated Articles") {
								// 	setSelectedTab("");
								// } else {
								setSelectedTab("Generated Articles");
								// }
							}
						},
						{
							name: "Published Articles",
							onClick: () => {
								// if (selectedTab === "Published Articles") {
								// 	setSelectedTab("");
								// } else {
								setSelectedTab("Published Articles");
								// }
							}
						},
						{
							name: "Scheduled Articles",
							onClick: () => {
								// if (selectedTab === "Archived Articles") {
								// 	setSelectedTab("");
								// } else {
								setSelectedTab("Scheduled Articles");
								// }
							}
						},
						{
							name: "Archived Articles",
							onClick: () => {
								// if (selectedTab === "Archived Articles") {
								// 	setSelectedTab("");
								// } else {
								setSelectedTab("Archived Articles");
								// }
							}
						},
					]}
					bulkActions={!selectedRows?.rows.length ? [] : availableBulkActions}
					bulkActionsEnabled={bulkActionsEnabled}
					applyAction={(action) => {
						if (action === "Generate") {
							setModalText("Processing request. Please wait...");
							setRequestModalActive(true);
							let articleUIDs: Array<string> = selectedRows?.rows.map(row => {
								return (row.original as ArticleTitle).articleUID
							}) || [];
							generateSelectedArticles.mutate(articleUIDs, {
								onSuccess: () => {
									refetch().then(() => {
										setRequestModalActive(false);
										props.successAlertRef.current?.show("Articles are being generated. Please wait for a moment.");
									});
								},
								onError: () => {
									setRequestModalActive(false);
									props.failAlertRef.current?.show("Failed to generate articles. Please try again after some time.");
								}
							});
						} else if (action === "Publish") {
							setModalText("Processing request. Please wait...");
							setRequestModalActive(true);
							let articleUIDs: Array<string> = selectedRows?.rows.map(row => {
								return (row.original as ArticleTitle).articleUID
							}) || [];
							postSelectedArticles.mutate({
								articlesUID: articleUIDs,
								selectedIntegration: selectedIntegration
							}, {
								onSuccess: () => {
									setRequestModalActive(false);
									refetch().then(() => {
										props.successAlertRef.current?.show("Articles was posted to your site successfully!");
									});
								},
								onError: () => {
									setRequestModalActive(false);
									props.failAlertRef.current?.show("Failed to post articles. Please try again after some time.");
								}
							});
						} else if (action === "Archive") {
							setModalText("Processing request. Please wait...");
							setRequestModalActive(true);
							let articleUIDs: Array<string> = selectedRows?.rows.map(row => {
								return (row.original as ArticleTitle).articleUID
							}) || [];
							archiveSelectedArticles.mutate({ articlesUID: articleUIDs, archiveType: "archive" }, {
								onSuccess: () => {
									setRequestModalActive(false);
									refetch().then(() => {
										props.successAlertRef.current?.show("Articles are being archived. Please wait for a moment.");
									});
								},
								onError: () => {
									setRequestModalActive(false);
									props.failAlertRef.current?.show("Failed to archive articles. Please try again after some time.");
								}
							});
						} else if (action === "Unarchive") {
							setModalText("Processing request. Please wait...");
							setRequestModalActive(true);
							let articleUIDs: Array<string> = selectedRows?.rows.map(row => {
								return (row.original as ArticleTitle).articleUID
							}) || [];
							archiveSelectedArticles.mutate({ articlesUID: articleUIDs, archiveType: "unarchive" }, {
								onSuccess: () => {
									setRequestModalActive(false);
									refetch().then(() => {
										props.successAlertRef.current?.show("Articles are being unarchived. Please wait for a moment.");
									});
								},
								onError: () => {
									setRequestModalActive(false);
									props.failAlertRef.current?.show("Failed to unarchive articles. Please try again after some time.");
								}
							});
						}
					}}
				/>
			</>
		)
	}
}
