/* eslint-disable jsx-a11y/anchor-is-valid */
import { useNavigate, useParams, useRouteLoaderData } from "react-router-dom";
import { useMemo, useEffect, useRef, useState, Dispatch, SetStateAction, MutableRefObject, Suspense } from "react";
import GenericButton from "../../components/GenericButton/GenericButton";
import SimpleMdeReact, { SimpleMdeToCodemirrorEvents } from "react-simplemde-editor";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
	articleIsGeneratedQuery,
	editTitleMutation,
	loadArticleContentQuery,
	postArticleMutation,
	saveArticleMutation,
	rephraseSentence,
	saveArticleFeaturedImgMutation,
	saveArticleImageMutation,
	regenerateTitleMutation,
	regenerateAIContentImgMutation,
	scheduleArticleMutation
} from "../../utils/api";
import { Player } from "@lottiefiles/react-lottie-player";
import { createColumnHelper, ColumnDef } from "@tanstack/react-table";
import AbunTable from "../../components/AbunTable/AbunTable";
import "./ArticleEditor.scss";
import AbunModal from "../../components/AbunModal/AbunModal";
import LinkButton from "../../components/LinkButton/LinkButton";
import { pageURL } from "../routes";
import ErrorAlert from "../../components/ErrorAlert/ErrorAlert";
import SuccessAlert from "../../components/SuccessAlert/SuccessAlert";
import AbunButton from "../../components/AbunButton/AbunButton";
import Input from "../../components/Input/Input";
import ImageUpload from "../../components/Input/ImageUpload";
import Icon from "../../components/Icon/Icon";
import { useDownloadFile } from "../../hooks/download";
import { BasePageData } from "../Base/Base";
import "easymde/dist/easymde.min.css"
import TextArea from "../../components/TextArea/TextArea";
import loadingIcon from "../../assets/images/loadingIcon.webp"
import BasicDateTimePicker from "../../components/DateTimePicker/DateTimePicker";
import dayjs from "dayjs";
import { Dayjs } from "dayjs";
import { NavLink } from "react-router-dom";
import { Skeleton } from "@mui/material";
import { AllIntegrations } from "../Articles/ShowV2Articles";

interface ArticleIsGenerated {
	generated: boolean
	failed: boolean
	title: string
	keyword: string
	traffic: number
	keyword_hash: string
	keyword_project_id: string
	keyword_project_name: string
}


interface GetArticleContentResponse {
	is_posted: boolean
	posted_to: string
	processing: boolean
	title: string
	content: string
	keyword: string
	traffic: number
	internal_links: number | null
	external_links: number | null
	image_count: number | null
	word_count: number | null
	featured_image: string
	all_integrations_with_unique_id: Array<AllIntegrations>
	feature_image_template: Array<FeatureImageTemplate>
	selected_template: string
	external_backlinks_preference: string
	image_source: string
	article_description: string
	article_scheduled_for_posting: boolean
	article_scheduled_datetime: string
	article_feedback: string
}

type FeatureImageTemplate = {
	template_id: string,
	sample_image_url: string
}

type PublishButtonTextAndIconName = {
	buttonText: string
	iconName: "wordpress-blue" | "wordpress-green" | "webflow-blue" | "webflow-green" | "wix-blue" | "wix-green" | "integration";
}

interface ArticleEditingTabsProps {
	articleContent: string | undefined
	setArticleContent: Dispatch<SetStateAction<string | undefined>>
	saveContent: (updatedArticleContent?: string, updatedArticleMetaDescription?: string) => void;
	errorAlertRef: MutableRefObject<any>
	successAlertRef: MutableRefObject<any>
	featuredImgTemplates: Array<FeatureImageTemplate>
	setFeaturedImageURL: Dispatch<SetStateAction<string | null>>
	setSelectedTemplate: Dispatch<SetStateAction<string>>
	selectedTemplate: string
	articleUid: string
	externalBacklinksPreference: string
	imageSource: string
	articlePosted: boolean
	articleMetaDescription: string
	setArticleMetaDescription: Dispatch<SetStateAction<string>>
	saveButtonText: string
	disableSave: boolean
}

interface ImagesTabProps {
	articleContent: string | undefined
	currentTab: string | undefined
	imageSource: string
	setArticleContent: Dispatch<SetStateAction<string | undefined>>
	saveContent: (updatedArticleContent?: string, updatedArticleMetaDescription?: string) => void;
	errorAlertRef: MutableRefObject<any>
	successAlertRef: MutableRefObject<any>
}

interface LinksTabProps {
	articleContent: string | undefined
	currentTab: string | undefined
	setArticleContent: Dispatch<SetStateAction<string | undefined>>
	setCurrentTab: Dispatch<SetStateAction<string | undefined>>
	saveContent: (updatedArticleContent?: string, updatedArticleMetaDescription?: string) => void;
	errorAlertRef: MutableRefObject<any>
	successAlertRef: MutableRefObject<any>
	externalBacklinksPreference: string
}

interface FeaturedImagesTabProps {
	articleContent: string | undefined
	currentTab: string | undefined
	errorAlertRef: MutableRefObject<any>
	successAlertRef: MutableRefObject<any>
	featuredImgTemplates: Array<FeatureImageTemplate>
	setFeaturedImageURL: Dispatch<SetStateAction<string | null>>
	setSelectedTemplate: Dispatch<SetStateAction<string>>
	selectedTemplate: string
	articleUid: string
}

interface MetaDataTabProps {
	articleContent: string | undefined
	currentTab: string | undefined
	articleMetaDescription: string
	articlePosted: boolean
	setArticleMetaDescription: Dispatch<SetStateAction<string>>
	saveContent: (updatedArticleContent?: string, updatedArticleMetaDescription?: string) => void;
	errorAlertRef: MutableRefObject<any>
	successAlertRef: MutableRefObject<any>
}


type Link = {
	linkHref: string
	linkTxt: string
	rel?: string
}

type Image = {
	imgSrc: string
	altTxt: string
	credit: string
	creditLink : string
}


type Tab = "images" | "internalLinks" | "externalLinks" | "featuredImageTemplate";

export default function ArticleEditor() {
	const delay = 1000;
	// ------------------- REACT ROUTER -------------------
	const { articleUID } = useParams();
	const navigate = useNavigate();
	const basePageData = useRouteLoaderData("base") as BasePageData;
	const { active_website_domain } = basePageData;

	// ------------------- NON-STATE CONSTANTS -------------------
	const defaultFeaturedImageURL: string = "https://res.cloudinary.com/diaiivikl/image/upload/v1690186987/" +
		"featured_image_1200x628.png"

	// ------------------- STATES -------------------
	const [
		articlePosted,
		setArticlePosted
	] = useState(false);

	const [
		integrationDone,
		setIntegrationDone
	] = useState(false);

	const [
		openDropdown,
		setOpenDropdown
	] = useState("");

	const [
		selectedIntegration,
		setSelectedIntegration
	] = useState("");

	const [
		editTitleModalActive,
		setEditTitleModalActive
	] = useState(false);

	const [
		integrationModalActive,
		setIntegrationModalActive
	] = useState(false);

	const [
		publishModalActive,
		setPublishModalActive
	] = useState(false);

	const [
		processing,
		setProcessing
	] = useState(true);

	const [
		articleTitle,
		setArticleTitle
	] = useState("");

	const [
		keyword,
		setKeyword
	] = useState<string | null>(null);

	const [
		keywordTraffic,
		setKeywordTraffic
	] = useState<number | null>(null);

	const [
		internalLinks,
		setInternalLinks
	] = useState<number | null>(null);

	const [
		externalLinks,
		setExternalLinks
	] = useState<number | null>(null);

	const [
		imageCount,
		setImageCount
	] = useState<number | null>(null);

	const [
		wordCount,
		setWordCount
	] = useState<number | null>(null);

	const [
		featuredImageURL,
		setFeaturedImageURL
	] = useState<string | null>(null);

	const [
		articleContent,
		setArticleContent
	] = useState<string | undefined>(undefined);

	const [
		featuredImgTemplates,
		setFeaturedImgTemplates
	] = useState<Array<FeatureImageTemplate>>([]);


	const [
		selectedTemplate,
		setSelectedTemplate
	] = useState<string>("");

	const [
		externalBacklinksPreference,
		setExternalBacklinksPreference
	] = useState<string>("");

	const [
		saveButtonText,
		setSaveButtonText
	] = useState("Save Changes");

	const [
		disableSave,
		setDisableSave
	] = useState(false);

	const [
		changeTitleValue,
		setChangeTitleValue
	] = useState("");

	// selected text
	const [
		selection,
		setSelection
	] = useState<string>("");

	// first time
	const [
		firstTime,
		setFirstTime
	] = useState(true);

	const [
		imageSource,
		setImageSource
	] = useState<string>("");

	const [
		articleScheduledForPosting,
		setArticleScheduledForPosting
	] = useState<boolean>(false);

	const [
		articleScheduledDatetime,
		setArticleScheduledDatetime
	] = useState<Dayjs | null>(null);

	const [
		selectPublishButtonTextAndIcon,
		setSelectPublishButtonTextAndIcon
	] = useState<PublishButtonTextAndIconName>({buttonText: "", iconName: "wordpress-blue"});

	const [
		keywordProjectId,
		setKeywordProjectId
	] = useState<string>("");

	const [
		keywordProjectName,
		setKeywordProjectName
	] = useState<string>("");

	const [
		keywordHash,
		setKeywordHash
	] = useState<string>("");

	const [showRegenerateConfirmModal, setShowRegenerateConfirmModal] = useState<boolean>(false);

	const [ articleMetaDescription, setArticleMetaDescription ] = useState<string>("");

	const [schedulePublishDateTime, setSchedulePublishDateTime] = useState<Dayjs | null>(dayjs().add(1, "day"));

	const [isDateTimePickerOpen, setIsDateTimePickerOpen] = useState<boolean>(false);

	const [articleFeedback, setArticleFeedback] = useState<string>("no_feedback");

	const [selectedIntegrationUniqueID, setSelectedIntegrationUniqueID] = useState("");

	const [integrationWithUniqueID, setIntegrationWithUniqueID] = useState<Array<AllIntegrations>>([]);

	const [publishArticleModalActive, setPublishArticleModalActive] = useState(false);

	// ------------------- QUERIES -------------------
	const articleIsGenerated = useQuery(articleIsGeneratedQuery(articleUID as string));
	const loadArticleContent = useQuery(loadArticleContentQuery(articleUID as string));

	// ------------------- CUSTOM EVENTS -------------------
	const customEvents = useMemo(() => {
		return {
			cursorActivity: (instance) => {
				const selection = instance.getSelection();
				const coordinates = instance.cursorCoords();
				const scrollInfo = instance.getScrollInfo();
				// the replaceTextByButton appears next to the cursor
				const replaceTextByButton = document.getElementById("replaceTextByButton");
				if (replaceTextByButton && selection !== "") {
					let top: number = coordinates.top - 150;
					let left: number = coordinates.left - 375;
					// if top exceeds the height of the text editor, set it to the height of the text editor
					if (top >= (scrollInfo.clientHeight - 5)) {
						top = scrollInfo.clientHeight;
					}
					// if left exceeds the width of the text editor, set it to the width of the text editor
					if (left >= (scrollInfo.clientWidth - 50)) {
						left = scrollInfo.clientWidth - 150;
					}
					// if left exceeds the window width, set it to the window width
					if (left >= (window.innerWidth - 475)) {
						left = window.innerWidth - 475;
					}
					replaceTextByButton.style.display = "block";
					replaceTextByButton.style.left = `${left}px`;
					replaceTextByButton.style.top = `${top}px`;
				}
				// hide the button if the selection is empty
				if (selection === "" && replaceTextByButton) {
					replaceTextByButton.style.display = "none";
				}
				const isMultipleSentencesSelected = selection.split(/[.!?]/).length > 1;
				const containsMarkdownCommands = /```|~~|\*|_|>|#/.test(selection);

				if ((isMultipleSentencesSelected || containsMarkdownCommands) && replaceTextByButton) {
					replaceTextByButton.style.display = "none";
				}
				setSelection(selection);
			},
		} as SimpleMdeToCodemirrorEvents;
	}, []);

	const anOptions = useMemo(() => {
		return {
			spellChecker: false,
			autosave: {
				enabled: false,
				uniqueId: "article-editor",
				delay,
			},
			toolbar: [
				'bold',
				'italic',
				'quote',
				'unordered-list',
				'ordered-list',
				'link',
				'image',
				'strikethrough',
				'code',
				'table',
				'undo',
				'redo',
				'heading',
				'heading-bigger',
				'heading-smaller',
				'heading-1',
				'heading-2',
				'heading-3',
				'horizontal-rule',
				'preview',
				'side-by-side',
				'fullscreen'
			] as const,
			sideBySideFullscreen: false,
			hideIcons: ["guide"]
		};
	}, [delay]);

	const handleReplaceTextByButton = () => {
		// disable the button
		const replaceTextByButton = document.getElementById("replaceTextByButton");
		if (replaceTextByButton) {
			replaceTextByButton.classList.add("is-loading");
			replaceTextByButton.setAttribute("disabled", "true");
		}
		rephraseSentence({
			sentence: selection
		}).then(response => {
			console.log(
				"Original Sentence: " + selection + "\n" +
				"Rephrased Sentence: " + response['data']['rephrased_sentence']
			);
			const simpleMdeInstance = (document.querySelector(".CodeMirror") as any).CodeMirror;
			const cursorPosition = simpleMdeInstance.getCursor();
			simpleMdeInstance.replaceSelection(response['data']['rephrased_sentence']);
			// enable the button
			if (replaceTextByButton) {
				replaceTextByButton.classList.contains("is-loading") && replaceTextByButton.classList.remove("is-loading");
				replaceTextByButton.hasAttribute("disabled") && replaceTextByButton.removeAttribute("disabled");
			}
			simpleMdeInstance.setCursor(cursorPosition);
		}
		).catch(error => {
			console.error(error);
		});
	};

	function GetWordCount(data: string): number {
		var pattern = /[a-zA-Z0-9_\u00A0-\u02AF\u0392-\u03c9\u0410-\u04F9]+|[\u4E00-\u9FFF\u3400-\u4dbf\uf900-\ufaff\u3040-\u309f\uac00-\ud7af]+/g;
		var m = data.match(pattern);
		var count = 0;
		if (m === null) return count;
		for (var i = 0; i < m.length; i++) {
			if (m[i].charCodeAt(0) >= 0x4E00) {
				count += m[i].length;
			} else {
				count += 1;
			}
		}
		return count;
	}

	function setPublishButtonTextAndIconName(articlePosted: boolean, integrationDone: boolean, postedTo: string, integration: string){
		let buttonText = "Publish to your website";

		if (articlePosted){
			switch (postedTo) {
				case "webflow":
					setSelectPublishButtonTextAndIcon({buttonText: buttonText, iconName: "webflow-green"});
					break;
				case "wordpress":
					setSelectPublishButtonTextAndIcon({buttonText: buttonText, iconName: "wordpress-green"});
					break;
				case "wix":
					setSelectPublishButtonTextAndIcon({buttonText: buttonText, iconName: "wix-green"});
					break;
				default:
					console.error(`Invalid PostedTo ${postedTo}`);
			}
		} else {
			if (integrationDone){
				switch (integration) {
					case "webflow":
						setSelectPublishButtonTextAndIcon({buttonText: buttonText, iconName: "webflow-blue"});
						break;
					case "wordpress":
						setSelectPublishButtonTextAndIcon({buttonText: buttonText, iconName: "wordpress-blue"});
						break;
					case "wix":
						setSelectPublishButtonTextAndIcon({buttonText: buttonText, iconName: "wix-blue"});
						break;
					default:
						console.error(`Invalid Integration ${integration}`);
				}
			} else {
				setSelectPublishButtonTextAndIcon({buttonText: buttonText, iconName: "integration"});
			}
		}
	}

	function setIntegrationAndHideDropDownContent(integrate: string){
		setSelectedIntegration(integrate);
		setOpenDropdown("");
		setPublishButtonTextAndIconName(articlePosted, integrationWithUniqueID.length > 0, "", getIntegrationName(integrate));
	}

	// ------------------- MUTATIONS -------------------
	const saveArticle = useMutation(saveArticleMutation);
	const postArticle = useMutation(postArticleMutation);
	const editTitle = useMutation(editTitleMutation);
	const regenerateTitle = useMutation(regenerateTitleMutation);
	const scheduleArticle = useMutation(scheduleArticleMutation);

	// const downloadArticleMut = useMutation({
	// 	mutationKey: ["downloadArticle"],
	// 	mutationFn: downloadArticle,
	// 	cacheTime: 0,
	// 	retry: retryFn,
	// 	onSuccess: data => {
	// 		console.log(data);
	// 	},
	// 	onError: error => {
	// 		console.log(error);
	// 	}
	// })

	// ----------------------- REFS -----------------------
	const successAlertRef = useRef<any>(null);
	const errorAlertRef = useRef<any>(null);

	// ----------------------- HOOKS -----------------------
	const articleMarkdownDownload = useDownloadFile({
		downloadPath: `/api/frontend/download/article?article_uid=${articleUID}&download_type=md`,
		getFileName: () => articleUID + ".md",
		onError: error => {
			console.error(error)
		},
	});
	const articleHtmlDownload = useDownloadFile({
		downloadPath: `/api/frontend/download/article?article_uid=${articleUID}&download_type=html`,
		getFileName: () => articleUID + ".html",
		onError: error => {
			console.error(error)
		},
	});
	// const articleDocxDownload = useDownloadFile({
	// 	downloadPath: `/api/frontend/download/article?article_uid=${articleUID}&download_type=docx`,
	// 	getFileName: () => articleUID + ".docx",
	// 	onError: error => {
	// 		console.error(error)
	// 	},
	// });

	// ----------------------- EFFECTS -----------------------
	useEffect(() => {
		document.title = "Article Editor | Abun"
	}, []);

	// fire "load article" api if article is generated
	useEffect(() => {
		if (articleIsGenerated.data) {
			let responseData = (articleIsGenerated.data as any)['data'] as ArticleIsGenerated;

			setArticleTitle(responseData.title);
			setKeyword(responseData.keyword);
			setKeywordTraffic(responseData.traffic);
			setKeywordHash(responseData.keyword_hash);
			setKeywordProjectId(responseData.keyword_project_id);
			setKeywordProjectName(responseData.keyword_project_name);

			if (responseData.generated) {
				loadArticleContent.refetch().then();
			}

			if (responseData.failed && keywordHash && keywordProjectId){
				navigate(`/keyword-project/${keywordProjectId}/titles/${keywordHash}`);
			}

		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [articleIsGenerated.data]);

	useEffect(() => {
		if (loadArticleContent.data) {
			let responseData = (loadArticleContent.data as any)['data'] as GetArticleContentResponse;
			setArticlePosted(responseData.is_posted);
			setProcessing(responseData.processing);
			setArticleContent(responseData.content);
			setFeaturedImgTemplates(responseData.feature_image_template);
			setSelectedTemplate(responseData.selected_template);
			setExternalBacklinksPreference(responseData.external_backlinks_preference);
			setArticleTitle(responseData.title);
			setChangeTitleValue(responseData.title);
			setInternalLinks(responseData.internal_links);
			setExternalLinks(responseData.external_links);
			setWordCount(responseData.word_count);
			setImageCount(responseData.image_count);
			setFeaturedImageURL(responseData.featured_image);
			setIntegrationDone(responseData.all_integrations_with_unique_id.length > 0)
			setImageSource(responseData.image_source);
			setArticleScheduledForPosting(responseData.article_scheduled_for_posting);
			setArticleScheduledDatetime(dayjs(responseData.article_scheduled_datetime));
			setSchedulePublishDateTime(dayjs(responseData.article_scheduled_datetime));
			setIntegrationWithUniqueID(responseData.all_integrations_with_unique_id);
			setArticleFeedback(responseData.article_feedback);

			// set Publish button text and icon name
			setPublishButtonTextAndIconName(responseData.is_posted, responseData.all_integrations_with_unique_id.length > 0, responseData.posted_to, responseData.all_integrations_with_unique_id.length > 0 ? getIntegrationName(responseData.all_integrations_with_unique_id[0].integrationName) : "");
			setSelectedIntegration(responseData.all_integrations_with_unique_id.length > 0 ? responseData.all_integrations_with_unique_id[0].integrationName : "");

			// set meta description
			setArticleMetaDescription(responseData.article_description);
		}
	}, [loadArticleContent.data]);

	useEffect(() => {
		if (articleContent?.trim().length) {
			setWordCount(GetWordCount(articleContent));

			// Regular expression to match href attributes within <a> tags
			const hrefRegex = /href=(["'])(.*?)\1/g;
			const links = articleContent.matchAll(hrefRegex);

			// Regular expression to match src attributes within <img> tags
			const imgSrcREgex = /<img\s+(?:[^>]*?\s+)?src=(["'])(.*?)\1/g;
			const imageTags = articleContent.matchAll(imgSrcREgex);
			let imagesNo = 0;
			let externalLinks = 0;
			let internalLinks = 0;

			// Regular expression to match Markdown-style links [visit our site](https://abun.com/)
			const markdownLinkRegex = /\[([^\]]+)\]\(([^)]+)\)/g;
			const markdownLinks = articleContent.matchAll(markdownLinkRegex);

			// Regular expression to match Markdown-style image syntax ![]()
			const markdownImgRegex = /!\[.*?\]\((.*?)\)/g;
			const markdownImages = articleContent.matchAll(markdownImgRegex);

			for (const link of links) {
				const href = link[2];

				if(href.includes("unsplash")) continue;//don't count unsplash img credit links

				if (href && !href.includes(active_website_domain as any)) {
					externalLinks++;
				} else if (href && href.includes(active_website_domain as any)) {
					internalLinks++;
				}
			}

			for (const markdownLink of markdownLinks) {
				const href = markdownLink[2];
				if (href && !href.includes(active_website_domain as any)) {
					externalLinks++;
				} else if (href && href.includes(active_website_domain as any)) {
					internalLinks++;
				}
			}

			for (const image of imageTags) {
				const src = image[2];
				if (src) {
					imagesNo++;
				}
			}

			for (const markdownImage of markdownImages) {
				const src = markdownImage[1];
				if (src) {
					imagesNo++;
				}
			}

			setExternalLinks(externalLinks);
			setInternalLinks(internalLinks);
			setImageCount(imagesNo);
		}
	}, [articleContent, active_website_domain]);



	// =================================================
	// ------------------- MAIN CODE -------------------
	// =================================================

	function goBack() {
		navigate(-1);
	}

	function getIntegrationName(integration: string){
		if (integration.includes("wordpress")){
			return "wordpress";
		} else if (integration.includes("webflow")){
			return "webflow";
		} else {
			return "wix";
		}
	}

	// optionally accepts updatedArticleContent and updatedArticleMetaDescription
	function saveContent(updatedArticleContent?: string, updatedArticleMetaDescription?: string, updatedArticleFeedback?: string) {
		if (articleContent && articleUID && articleMetaDescription && !processing) {
			setDisableSave(true);
			setSaveButtonText("Saving...");
			saveArticle.mutate({
				article_uid: articleUID,
				article_content: updatedArticleContent ? updatedArticleContent : articleContent,
				article_description: updatedArticleMetaDescription ? updatedArticleMetaDescription : articleMetaDescription,
				article_feedback: updatedArticleFeedback ? updatedArticleFeedback : articleFeedback
			}, {
				onSuccess: () => {
					setSaveButtonText("Saved Successfully!");
					setDisableSave(false);
					setTimeout(() => {
						setSaveButtonText("Save Changes");
					}, 5000);
				},
				onError: () => {
					setSaveButtonText("Save Changes");
					setDisableSave(false);
				}
			});
		}
	}

	function renderMainContent() {
		if (processing) {
			return (
				<div className={"is-flex is-flex-direction-column is-align-items-center"}>
					<Player
						autoplay
						loop
						src="https://lottie.host/91a433df-05fa-4ab3-94b2-2c2a0a16a67f/2SoIqH8Kh3.json"
						style={{ height: '300px', width: '300px' }}
					>
					</Player>
					<h2 className={"is-size-3 font-secondary has-text-weight-bold has-text-primary"}>
						An Amazing SEO Article is being cooked for your site!
					</h2>
					<p className={"is-size-5 mt-4"}>Article generation should take around 1-2 minutes</p>
					<ul className={"mt-4 mb-4"} style={{ listStyleType: "none" }}>
						<li className={"mb-2"}><Icon iconName={"green-checkmark-circle"} />&nbsp;&nbsp;SEO Optimized Article
						</li>
						<li className={"mb-2"}><Icon iconName={"green-checkmark-circle"} />&nbsp;&nbsp;With Image & Alt Text
						</li>
						<li className={"mb-2"}><Icon iconName={"green-checkmark-circle"} />&nbsp;&nbsp;With Internal Links</li>
						<li className={"mb-2"}><Icon iconName={"green-checkmark-circle"} />&nbsp;&nbsp;With External Links</li>
						<li className={"mb-2"}><Icon iconName={"green-checkmark-circle"} />&nbsp;&nbsp;With Featured Image</li>
					</ul>
				</div>
			)
		} else {
			return (
				<div className={"article-editor-page-editor-container content"} data-color-mode="light">
					<button id="replaceTextByButton" className="button is-rounded is-primary"
						style={{ display: "none" }}
						onClick={handleReplaceTextByButton}
					>Rephrase</button>
					{<SimpleMdeReact
							value={articleContent}
							events={customEvents}
							options={anOptions}
							className={"reset-font-style content"}
							onChange={setArticleContent}
							// Toggle side-by-side mode on load only
							getMdeInstance={(instance) => {
								try {
									if (instance.isSideBySideActive() === false) {
										firstTime && (instance as any).toggleSideBySide();
										setFirstTime(false);
									}
								} catch (error) {
									console.log(error);
								}
							}}
						/>
					}
				</div>
			)
		}
	}

	function postToBlogHandler(articleUID: string) {
		errorAlertRef.current?.close();
		successAlertRef.current?.close();

		setPublishModalActive(true);
		if (articleUID && selectedIntegration) {
			postArticle.mutate({articleUID: articleUID, selectedIntegration: selectedIntegration, selectedIntegrationUniqueID: selectedIntegrationUniqueID}, {
				onSuccess: () => {
					setPublishModalActive(false);
					successAlertRef.current?.show(`Article ${articleUID} was posted to your site successfully!`);
					setArticlePosted(true);
					setTimeout(() => {
						successAlertRef.current?.close();
					}, 5000);
				},
				onError: () => {
					setPublishModalActive(false);
					errorAlertRef.current?.show(
						"Oops! Something went wrong. Check your Email for more details or contact us if the issue persists."
					);
					setTimeout(() => {
						errorAlertRef.current?.close();
					}, 5000);
				}
			})
		} else {
			setPublishModalActive(false);
			errorAlertRef.current?.show("Key information is missing. Please try again.");
			setTimeout(() => {
				errorAlertRef.current?.close();
			}, 5000);
		}
	}

	function truncateSiteDomain(domain: string){
		if (domain.includes("webflow")){
			domain = domain.replace(new RegExp("webflow - ", 'g'), '');
		} else if (domain.includes("wix")){
			domain = domain.replace(new RegExp("wix - ", 'g'), '');
		} else {
			domain = domain.replace(new RegExp("wordpress - ", 'g'), '');
		}

		if (domain.length <= 20){
			return domain;
		} else {
			domain = domain.substring(0, 17) + "...";
		}

		return domain;
	}

	if (loadArticleContent.error) {
		return (
			<div className={"card mt-4 article-editor-page-card"}>
				<div className={"card-content"}>
					<div className={"content is-flex is-justify-content-center"}>
						<p>Oops! Something went wrong :(</p>
					</div>
				</div>
			</div>
		)

	} else if (loadArticleContent.isFetching) {
		return (
			<div className={"card mt-4 article-editor-page-card"}>
				<div className={"card-content"}>
					<div className={"content is-flex is-justify-content-center"}>
						<p style={{ textAlign: "center", fontSize: "1.3rem" }}>
							Loading Data...<Icon iconName={"spinner"} marginClass={"ml-5"} />
						</p>
					</div>
				</div>
			</div>
		)

	} else {
		return (
			<>
				{/* ------------------------------ REGENERATE TITLE MODAL ------------------------------ */}
				<AbunModal active={showRegenerateConfirmModal}
					headerText={"Please Confirm!"}
					closeable={true}
					hideModal={() => {
						setShowRegenerateConfirmModal(false);
						setEditTitleModalActive(true);
					}}>
					<div className="p-3 is-bold has-text-centered">
						<p className="is-size-5 mb-3">Are you sure you want to Regenerate Title?</p>
						<GenericButton text={regenerateTitle.isLoading ? "Regenerating Title..." : "Yes, Regenerate Title"}
							type={"success"}
							icon={regenerateTitle.isLoading ? "spinner" : "refresh"}
							disable={regenerateTitle.isLoading}
							additionalClassList={["mr-4"]}
							clickHandler={() => {
								if (articleUID) {
									successAlertRef.current?.close();
									errorAlertRef.current?.close();
									regenerateTitle.mutate({ articleUID: articleUID }, {
										onSuccess: (data) => {
											setArticleTitle(data['data']['title']);
											setChangeTitleValue(data['data']['title']);
											setFeaturedImageURL(data['data']['featured_image']);
											setShowRegenerateConfirmModal(false);
											setEditTitleModalActive(true);
											successAlertRef.current?.show("Article title for this post has been regenerated successfully!");
										},
										onError: (error) => {
											console.error(error);
											errorAlertRef.current?.show("Oops! Something went wrong. Please try again.");
										},
									});
								} else {
									console.error("article title regeneration failed due to missing article uid");
								}
							}} />
						<GenericButton text={"Cancel"}
							type={"primary"}
							additionalClassList={["mr-4"]}
							clickHandler={() => {
								setShowRegenerateConfirmModal(false);
								setEditTitleModalActive(true);
							}} />
					</div>
				</AbunModal>

				{/* ------------------------------ EDIT TITLE MODAL ------------------------------ */}
				<AbunModal active={editTitleModalActive}
					headerText={""}
					closeable={true}
					hideModal={() => {
						setEditTitleModalActive(false);
					}}>
					<label className={"label"}>
						Article Title:
						<input type="text" className={"input mt-2"} value={changeTitleValue} onChange={
							(event) => {
								setChangeTitleValue(event.target.value);
							}
						} />
					</label>
					<div className={"mt-6 is-flex is-justify-content-center"}>
						<GenericButton text={editTitle.isLoading ? "Saving Changes..." : "Save Changes"}
							type={"success"}
							icon={"floppy-disk"}
							disable={editTitle.isLoading}
							additionalClassList={["mr-4"]}
							clickHandler={() => {
								if (articleUID) {
									successAlertRef.current?.close();
									errorAlertRef.current?.close();
									editTitle.mutate({ articleUID: articleUID, title: changeTitleValue }, {
										onSuccess: (data) => {
											setArticleTitle(data['data']['title']);
											setChangeTitleValue(data['data']['title']);
											setFeaturedImageURL(data['data']['featured_image']);
											setShowRegenerateConfirmModal(false);
											setEditTitleModalActive(false);
											successAlertRef.current?.show("Article title for this post has been updated successfully!");
											setTimeout(() => {
												successAlertRef.current?.close();
											}, 5000);
										},
										onError: (error) => {
											console.error(error);
											errorAlertRef.current?.show("Oops! Something went wrong. Please try again.");
											setTimeout(() => {
												errorAlertRef.current?.close();
											}, 5000);
										},
									});
								} else {
									console.error("article title edit failed due to missing article uid");
								}
							}} />

						{/* <GenericButton text={"Regenerate Title"}
							type={"primary"}
							disable={regenerateTitle.isLoading}
							additionalClassList={["mr-4"]}
							clickHandler={() => {
								setShowRegenerateConfirmModal(true);
								setEditTitleModalActive(false);
							}} /> */}
					</div>
				</AbunModal>

				{/* ------------------------------ INTEGRATION MODAL ------------------------------ */}
				<AbunModal active={integrationModalActive}
					headerText={""}
					closeable={true}
					hideModal={() => {
						setIntegrationModalActive(false);
					}}>
					<div className={"has-text-centered"}>
						<h1 className={"is-size-3"}>Publish articles to your blog page!</h1>
						<p className={"mt-4"}>
							Start publishing articles to your blog page by setting up an Integration for your website.
							You can find it under <b>Settings</b> {">"} <b>Integration & Scheduling</b>
						</p>
						<LinkButton text={"Go to Settings"}
							linkTo={pageURL['settings'] + "?tab=integration"}
							type={"primary"}
							additionalClassList={["mt-5"]} />
					</div>
				</AbunModal>

				{/* ------------------------------ PUBLISH REQUEST MODAL ------------------------------ */}
				<AbunModal active={publishModalActive}
					headerText={""}
					closeable={false}
					hideModal={() => {
						setPublishModalActive(false)
					}}>
					<div className={"loadingData w-100 is-flex is-justify-content-center is-align-items-center"}>
						<img className={"image"} src={loadingIcon} alt="loading" />
					</div>
					<p className={"is-size-4 has-text-centered mb-4"}>
						Posting to your website blog. Please wait...
					</p>
				</AbunModal>

				{/* ------------------------------ PUBLISH ARTICLE MODAL ------------------------------ */}
				<AbunModal active={publishArticleModalActive}
									headerText={"Publish Article"}
									closeable={true}
									modelWidth="750px"
									hideModal={() => {
										setPublishArticleModalActive(false);
									}}>
					<div>
						{selectedIntegration ?
						<>
							<h4 className={"is-size-4 has-text-centered"}>Article will be published on <b>{selectedIntegration.replace(new RegExp("webflow - ", 'g'), '')}</b></h4>
							<GenericButton
								text="Publish"
								type="primary"
								additionalClassList={["mt-5", "float-left"]}
								clickHandler={() => {
									setPublishArticleModalActive(false);
									postToBlogHandler(articleUID as string);
								}} />
						</> :
						<h4 className={"is-size-4 has-text-centered"}>Please select an integration from the Dropdown.</h4>
						}
					</div>
				</AbunModal>

				<div className={"article-editor-page-header"}>
					<p className={"article-editor-page-title"}>
						<span style={{ cursor: "pointer" }} onClick={goBack}>
							<Icon iconName={'chevron-left'} />
						</span>&nbsp;&nbsp;
						Edit Article Content
					</p>
					{(!processing && !articlePosted) && <div className={"is-flex header-btn-container is-flex-wrap-wrap is-flex-direction-row"}>
						{/* ------------------ markdown download ------------------ */}
						<AbunButton type={"primary"}
							className={"is-small has-text-weight-bold"}
							clickHandler={() => {
								articleMarkdownDownload.download();
							}}>
							<Icon iconName={"download"} additionalClasses={["icon-white"]} />&nbsp;&nbsp;Download Markdown
						</AbunButton>
						{/* ------------------ html download ------------------ */}
						<AbunButton type={"primary"}
							className={"ml-2 is-small has-text-weight-bold"}
							clickHandler={() => {
								articleHtmlDownload.download();
							}}>
							<Icon iconName={"download"} additionalClasses={["icon-white"]} />&nbsp;&nbsp;Download HTML
						</AbunButton>
						{/* ------------------ .docx download ------------------ */}
						{/*<AbunButton type={"primary"}*/}
						{/*						className={"ml-2 is-small has-text-weight-bold"}*/}
						{/*						clickHandler={() => {*/}
						{/*							articleDocxDownload.download();*/}
						{/*						}}>*/}
						{/*		<Icon iconName={"download"} additionalClasses={["icon-white"]}/>&nbsp;&nbsp;Download .docx*/}
						{/*</AbunButton>*/}
					</div>}
				</div>

				{/* ------------------------- Breadcrumb ------------------------- */}
				<Suspense fallback={<Skeleton />}>
					{ keywordProjectId && keywordProjectName && keywordHash && keyword &&
						<div className={"breadcrumb"}>
							<NavLink to={pageURL['keywordResearch']}>Keyword Research</NavLink> {">"} <NavLink to={pageURL['keywordResearch'] + `?keywordProjectId=${keywordProjectId}`}>{keywordProjectName}</NavLink> {">"} <NavLink to={`/keyword-project/${keywordProjectId}/titles/${keywordHash}`}>{keyword}</NavLink> {">"} {articleTitle}
						</div>
					}
				</Suspense>

				{/* ------------------------- Main Content Card ------------------------- */}
				<div className={"card mt-4 w-100"}>
					<div className={"card-content"}>
						<div className={"content"}>
							<section className={"article-data-section"}>
								{processing ?
									<img src="https://res.cloudinary.com/diaiivikl/image/upload/v1690186987/featured_image_1200x628.png"
										alt="featured_image"
										width={"100%"}
										height={"auto"} /> :
									<img src={featuredImageURL || defaultFeaturedImageURL}
										alt="featured_image"
										width={"100%"}
										height={"auto"} />
								}
								<div className={"article-data-section--title-and-stats"}>
									<h3 className={`is-size-3 has-text-weight-bold font-secondary ${isDateTimePickerOpen ? "mb-6": ""}`}>
										{articleTitle}
									</h3>
									<div className={"is-align-self-center"}>
										<GenericButton text={"Edit Title"}
											type={"success"}
											disable={processing || articlePosted}
											icon={"edit"}
											additionalClassList={["mr-4", "my-2"]}
											clickHandler={() => {
												setEditTitleModalActive(true);
											}} />
										<GenericButton text={selectPublishButtonTextAndIcon.buttonText}
											type={"primary"}
											disable={processing || articlePosted}
											icon={selectPublishButtonTextAndIcon.iconName}
											additionalClassList={["mr-4", "post-to-blog-button", "no-hover-background-color", "my-2"]}
											outlined={true}
											clickHandler={() => {
												if (!integrationDone) {
													setIntegrationModalActive(true);
												} else {
													if (articleUID) {
														if (integrationWithUniqueID.length > 1){
															setPublishArticleModalActive(true);
														} else {
															postToBlogHandler(articleUID);
														}
													} else {
														console.error("Post to Blog failed. Article UID missing.")
													}
												}
											}} />
											<GenericButton text={scheduleArticle.isLoading ? articleScheduledForPosting ? "Rescheduling..." : "Scheduling..." : articleScheduledForPosting ? "Reschedule Posting" : "Schedule Posting"}
												type={"primary"}
												disable={processing || articlePosted || scheduleArticle.isLoading}
												icon={"calendar-search"}
												additionalClassList={["mr-4", "my-2"]}
												clickHandler={() => {
													if (!integrationDone){
														setIntegrationModalActive(true);
													} else {
														setIsDateTimePickerOpen(true);
													}
												}} />
											{
												isDateTimePickerOpen &&
												<div style={{width: "50%"}}>
													<BasicDateTimePicker
														className="mb-4"
														label={articleScheduledForPosting ? "Reschedule Article" : "Schedule Article"}
														defaultDate={articleScheduledForPosting ? articleScheduledDatetime : schedulePublishDateTime}
														isOpen={isDateTimePickerOpen}
														onChange={(scheduleDate: Dayjs | null) => setSchedulePublishDateTime(scheduleDate)}
														onClose={() => {
															setIsDateTimePickerOpen(false);
															if (schedulePublishDateTime?.format("YYYY-MM-DD HH:mm:ss") === articleScheduledDatetime?.format("YYYY-MM-DD HH:mm:ss")){
																console.log("The scheduled date has not changed!");
															} else if (articleUID && schedulePublishDateTime) {
																successAlertRef.current?.close();
																errorAlertRef.current?.close();
																scheduleArticle.mutate({
																	articleUID: articleUID,
																	articleScheduleDate: schedulePublishDateTime,
																	integrationName: selectedIntegration,
																	integrationUniqueID: selectedIntegrationUniqueID,
																}, {
																	onSuccess: (data) => {
																		let responseData = (data as any)["data"];
									
																		if (responseData["success"]){
																			setArticleScheduledForPosting(true);
																			setArticleScheduledDatetime(schedulePublishDateTime);
																			successAlertRef.current?.show(articleScheduledForPosting ? "Article rescheduled successfully!" : "Article scheduled successfully!");
																		} else {
																			errorAlertRef.current?.show(responseData["message"]);
																		}
																	},
																	onError: (error) => {
																		console.error(error);
																		errorAlertRef.current?.show("Oops! Something went wrong. Please try again.");
																	},
																});
															} else {
																console.error("article publishing schedule failed due to missing article uid");
															}
														}}
														/>
												</div> 
											}
											{integrationWithUniqueID.length > 0 && !articlePosted && 
												<div className="publish-container-editor">
													<div className="dropdown">
														<button className={`dropdown-icon ${openDropdown === articleUID ? "rotate" : ""}`} onClick={() => setOpenDropdown(!articleUID || openDropdown === articleUID ? "" : articleUID)}>&#9662;</button>
														<div className={`dropdown-content ${openDropdown === articleUID ? "show" : ""}`}>
															{
																integrationWithUniqueID.map((integration, index) => (
																	<p key={index} onClick={() => {
																		setSelectedIntegrationUniqueID(integration.integrationUniqueID);
																		setIntegrationAndHideDropDownContent(integration.integrationName);
																	}}>{truncateSiteDomain(integration.integrationName)}</p>
																))
															}
														</div>
													</div>
												</div>
											}
									</div>
									<div className={"is-flex is-flex-direction-row is-justify-content-space-between is-align-items-end"}>
										<p className={"is-size-4"}>
											Keyword:&nbsp;
											<span className={"has-text-primary"}>{keyword === null ? "---" : keyword}</span>
										</p>
									</div>
									<div
										className={"stats-container is-flex is-align-items-end mt-3"}>
										<p className={"is-size-4"}>
											Word Count:&nbsp;
											<span className={"has-text-primary"}>{wordCount === null ? "---" : wordCount}</span>
										</p>
										<p className={"is-size-4"}>
											Keyword Traffic:&nbsp;
											<span className={"has-text-primary"}>{keywordTraffic === null ? "---" : keywordTraffic}</span>
										</p>
										<p className={"is-size-4"}>
											Internal Links:&nbsp;
											<span className={"has-text-primary"}>{internalLinks === null ? "---" : internalLinks}</span>
										</p>
										<p className={"is-size-4"}>
											External Links:&nbsp;
											<span className={"has-text-primary"}>{externalLinks === null ? "---" : externalLinks}</span>
										</p>
										<p className={"is-size-4"}>
											Images:&nbsp;
											<span className={"has-text-primary"}>{imageCount === null ? "---" : imageCount}</span>
										</p>
									</div>
								</div>
								{/* ------------------ Article Feedback ------------------ */}
								<div className={"article-feedback-container"}>
									<svg width="20" height="20" viewBox="0 0 25 25" fill="none" className={`positive-feedback ${articleFeedback === "positive" ? "active" : ""}`} onClick={() => {
										setArticleFeedback("positive");
										saveContent(undefined, undefined, "positive")
									}}>
										<path fill-rule="evenodd" clip-rule="evenodd" d="M3.75022 25C1.67903 25 0 23.3211 0 21.25V13.75C0 11.6789 1.67903 10 3.75022 10H5.47657L9.44153 2.07295C10.0394 0.877241 11.2249 0.0960319 12.5465 0.0082624L12.7958 0H13.1258C14.7827 0 16.1385 1.28942 16.2443 2.91953L16.2509 3.125V10H21.2491C21.496 10 21.7424 10.0244 21.9845 10.0728C23.943 10.4645 25.2377 12.3155 24.9634 14.2681L24.9265 14.4854L23.4264 21.9854C23.0904 23.6652 21.6628 24.894 19.9711 24.9935L19.749 25H3.75022ZM5.00029 12.5H3.75022C3.05982 12.5 2.50015 13.0596 2.50015 13.75V21.25C2.50015 21.9404 3.05982 22.5 3.75022 22.5H5.00029V12.5ZM13.1258 2.5H12.7958C12.3749 2.5 11.9862 2.71136 11.7563 3.05575L11.6777 3.19098L7.63241 11.2812C7.56732 11.4113 7.52563 11.5515 7.50885 11.6954L7.50044 11.8402V22.5H19.749C20.2952 22.5 20.7718 22.1466 20.9375 21.6377L20.9748 21.4951L22.4749 13.9951C22.6103 13.3182 22.1712 12.6597 21.4942 12.5243L21.3722 12.5061L21.2491 12.5H16.2509C14.9329 12.5 13.8531 11.4802 13.7577 10.1866L13.7508 10V3.125C13.7508 2.81818 13.5297 2.56299 13.2381 2.51007L13.1258 2.5Z" fill="#6C6C6C" fill-opacity="1" />
									</svg>
									<svg width="20" height="20" viewBox="0 0 25 25" fill="none" className={`negative-feedback ${articleFeedback === "negative" ? "active" : ""}`} onClick={() => {
										setArticleFeedback("negative");
										saveContent(undefined, undefined, "negative")
									}}>
										<path fill-rule="evenodd" clip-rule="evenodd" d="M3.75022 25C1.67903 25 0 23.3211 0 21.25V13.75C0 11.6789 1.67903 10 3.75022 10H5.47657L9.44153 2.07295C10.0394 0.877241 11.2249 0.0960319 12.5465 0.0082624L12.7958 0H13.1258C14.7827 0 16.1385 1.28942 16.2443 2.91953L16.2509 3.125V10H21.2491C21.496 10 21.7424 10.0244 21.9845 10.0728C23.943 10.4645 25.2377 12.3155 24.9634 14.2681L24.9265 14.4854L23.4264 21.9854C23.0904 23.6652 21.6628 24.894 19.9711 24.9935L19.749 25H3.75022ZM5.00029 12.5H3.75022C3.05982 12.5 2.50015 13.0596 2.50015 13.75V21.25C2.50015 21.9404 3.05982 22.5 3.75022 22.5H5.00029V12.5ZM13.1258 2.5H12.7958C12.3749 2.5 11.9862 2.71136 11.7563 3.05575L11.6777 3.19098L7.63241 11.2812C7.56732 11.4113 7.52563 11.5515 7.50885 11.6954L7.50044 11.8402V22.5H19.749C20.2952 22.5 20.7718 22.1466 20.9375 21.6377L20.9748 21.4951L22.4749 13.9951C22.6103 13.3182 22.1712 12.6597 21.4942 12.5243L21.3722 12.5061L21.2491 12.5H16.2509C14.9329 12.5 13.8531 11.4802 13.7577 10.1866L13.7508 10V3.125C13.7508 2.81818 13.5297 2.56299 13.2381 2.51007L13.1258 2.5Z" fill="#6C6C6C" fill-opacity="1" />
									</svg>
								</div>
							</section>
							<hr className={"mt-6 mb-6"} />
							{articleContent && <ArticleEditingTabs
								articleContent={articleContent}
								setArticleContent={setArticleContent}
								featuredImgTemplates={featuredImgTemplates}
								selectedTemplate={selectedTemplate}
								setSelectedTemplate={setSelectedTemplate}
								saveContent={saveContent}
								setFeaturedImageURL={setFeaturedImageURL}
								externalBacklinksPreference={externalBacklinksPreference}
								articleUid={articleUID as string}
								imageSource={imageSource}
								errorAlertRef={errorAlertRef}
								successAlertRef={successAlertRef}
								articleMetaDescription={articleMetaDescription}
								setArticleMetaDescription={setArticleMetaDescription}
								articlePosted={articlePosted}
								saveButtonText={saveButtonText}
								disableSave={disableSave}
								/>}
							{renderMainContent()}
						</div>
					</div>
				</div>
				<SuccessAlert ref={successAlertRef} />
				<ErrorAlert ref={errorAlertRef} />
			</>
		)
	}
}

export function ArticleEditingTabs(props: ArticleEditingTabsProps) {
	// --------------------- STATES ------------------------
	const [currentTab, setCurrentTab] = useState<Tab | string>();
	const { articleContent } = props;

	// --------------------- FUNCTIONS ------------------------

	function tabClickHandler(tabName: string) {
		if (currentTab === tabName) {
			setCurrentTab("");
		} else {
			setCurrentTab(tabName);
		}
	}


	// --------------------- MAIN JSX ------------------------

	return <div>
		<div className="tabs is-toggle mb-0">
			<ul>
				<li className={currentTab === "images" ? "is-active" : ""} onClick={() => tabClickHandler("images")}>
					<a>Images</a>
				</li>
				<li className={currentTab === "internalLinks" ? "is-active" : ""} onClick={() => tabClickHandler("internalLinks")}>
					<a>Internal Links</a>
				</li>
				<li className={currentTab === "externalLinks" ? "is-active" : ""} onClick={() => tabClickHandler("externalLinks")}>
					<a>External Links</a>
				</li>
				{/* hide featured image template tab for now using is-hidden */}
				<li className={currentTab === "featuredImageTemplate" ? "is-active is-hidden" : "is-hidden"} onClick={() => tabClickHandler("featuredImageTemplate")}>
					<a>Featured Image Templates</a>
				</li>
				<li className={currentTab === "articleMetaData" ? "is-active" : ""} onClick={() => tabClickHandler("articleMetaData")}>
					<a>Meta Data</a>
				</li>
			</ul>
			{/* ------------------ save edits ------------------ */}
			<GenericButton text={props.saveButtonText}
				icon={"floppy-disk"}
				type={"success"}
				additionalClassList={["is-small", "ml-6", "has-text-weight-bold"]}
				disable={props.disableSave}
				clickHandler={props.saveContent} />
		</div>
		<div className={"tab-content mt-1 mb-4"}>
			{currentTab === "images" &&
				<ImagesTab
					articleContent={articleContent}
					currentTab={currentTab}
					imageSource={props.imageSource}
					setArticleContent={props.setArticleContent}
					saveContent={props.saveContent}
					errorAlertRef={props.errorAlertRef}
					successAlertRef={props.successAlertRef}
				/>}
			{(currentTab === "internalLinks" || currentTab === "externalLinks") &&
				<LinksTab
					articleContent={articleContent}
					currentTab={currentTab}
					setArticleContent={props.setArticleContent}
					externalBacklinksPreference={props.externalBacklinksPreference}
					setCurrentTab={setCurrentTab}
					saveContent={props.saveContent}
					errorAlertRef={props.errorAlertRef}
					successAlertRef={props.successAlertRef}
				/>}
			{currentTab === "featuredImageTemplate" &&
				<FeaturedImagesTab
					articleContent={articleContent}
					currentTab={currentTab}
					featuredImgTemplates={props.featuredImgTemplates}
					selectedTemplate={props.selectedTemplate}
					setFeaturedImageURL={props.setFeaturedImageURL}
					setSelectedTemplate={props.setSelectedTemplate}
					errorAlertRef={props.errorAlertRef}
					successAlertRef={props.successAlertRef}
					articleUid={props.articleUid}
				/>}
			{currentTab === "articleMetaData" &&
				<MetaDataTab
					articleContent={articleContent}
					currentTab={currentTab}
					articleMetaDescription={props.articleMetaDescription}
					setArticleMetaDescription={props.setArticleMetaDescription}
					saveContent={props.saveContent}
					errorAlertRef={props.errorAlertRef}
					successAlertRef={props.successAlertRef}
					articlePosted={props.articlePosted}
				/>}
		</div>
	</div>
}

export function ImagesTab(props: ImagesTabProps) {
	// --------------------- STATES ------------------------
	const [images, setImages] = useState<Array<Image>>([]);
	const [imageBeingEdited, setImageBeingEdited] = useState<string>();
	const [newImageUrl, setNewImageUrl] = useState<string>("");
	const [newImageAlt, setNewImageAlt] = useState<string>("");
	const [newImageCredit, setNewImageCredit] = useState<string>("");
	const [uploadedImage, setUploadedImage] = useState<File | null>(null);
	const [invalidImageMessage, setInvalidImageMessage] = useState<string>();
	const { articleContent, setArticleContent, saveContent } = props;
	const [imageBeingRegenerated, setImageBeingRegenerated] = useState<string>();

	// --------------------- PARAMS --------------------------
	const { articleUID } = useParams();


	// ----------------------- MUTATIONS ---------------------
	const uploadArticleImage = useMutation(saveArticleImageMutation);
	const regenerateAIArticleImage = useMutation(regenerateAIContentImgMutation);

	// --------------------- EFFECTS ------------------------

	useEffect(() => {
		if (articleContent) {
			const articleImages: Image[] = [];

			// Regular expression to find all img tags with their sub/credits
			const imgRegex = /<img[^>]+src="([^">]+)"[^>]+alt="([^">]+)"[^>]+\/>(?:<br\/?>)?(?:<sub>(.*?)<\/sub>)?/g;

			for (const match of articleContent.matchAll(imgRegex)) {
				const imgSrc: string = match[1];
				const altTxt: string = match[2];
				const subTagContent: string = match[3] || "";

				let credit: string = "";
				let creditLink: string = "";

				// Check if the sub tag content contains an <a> tag,in case of unsplash images
				const anchorTagRegex = /<a\s+(?:[^>]*?)href="([^"]+)"(?:[^>]*?)>(.*?)<\/a>/;
				const anchorTagMatch = subTagContent.match(anchorTagRegex);

				if (anchorTagMatch) {
					creditLink = anchorTagMatch[1];
					credit = anchorTagMatch[2];
				} else {
					credit = subTagContent;
				}

				articleImages.push({ imgSrc, altTxt, credit, creditLink });
			}

			// Regular expression to find Markdown-style image syntax ![]()
			const markdownImgRegex = /!\[([^\]]+)\]\(([^)]+)\)/g;
			for (const match of articleContent.matchAll(markdownImgRegex)) {
				const altTxt: string = match[1];
				const imgSrc: string = match[2];

				// Set credit for Markdown-style images to an empty string
				articleImages.push({ imgSrc, altTxt, credit: "", creditLink: "" });
			}

			setImages(articleImages);
		}
  }, [articleContent]);


	useEffect(() => {
		if (!images.find(img => img.imgSrc === imageBeingEdited)) {
			setImageBeingEdited(undefined);
		}
	}, [images, imageBeingEdited]);


  // --------------------- FUNCTIONS ------------------------

	function removeImageHandler(imgSrc: string) {

		// Regular expression to match <img> tags optionallly with their credit/sub tags
		// this one
		const imgTagRegex = new RegExp(`<img\\s+src="${imgSrc.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')}".*?\\/>\\s*(<br\\s*\\/>\\s*)?(<sub>.*?<\\/sub>)?`, 'g');

		// Regular expression to match Markdown-style image syntax ![]()
		const markdownImgRegex = new RegExp(`!\\[.*\\]\\(${imgSrc.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')}\\)`, 'g');

		let updatedArticleContent = articleContent?.replace(imgTagRegex, '')?.replace(markdownImgRegex, '');

		setArticleContent(updatedArticleContent);
		saveContent(updatedArticleContent);
	}

	function handleImageUpload(imageFile: File | null) {
		setUploadedImage(null);
		setInvalidImageMessage(undefined);

		if(imageFile?.size  && imageFile.size > 256000) { // max file size 
			setInvalidImageMessage("Please only upload a file of size upto 250kb");
			return;
		}
		setUploadedImage(imageFile);

		/// send the image to server get the image url
		if(articleUID && imageFile?.type) {
			uploadArticleImage.mutate({
				article_type : articleUID.includes("how-to") ? "how-to" : "general", 
				article_uid : articleUID, 
				extension: imageFile.type.split("/")[1], 
				image : imageFile}, {
				onSuccess: (data) => {
					if(data.data.image_url) {
						setNewImageUrl(data.data.image_url);
						setInvalidImageMessage("Please enter the alt text and credit(optional) for the Uploaded image.")
					}
				},
				onError: (error) => {
					console.log(error);
				},
			});
		}
	};

	function saveUpdatedImageHandler() {
		if (!newImageUrl || !newImageAlt) {
			setInvalidImageMessage("Please provide image URL and alt text.");
			return;
		}

		// Check if newImageAlt is too long or sort
		if(newImageAlt.split(" ").length < 2 || newImageAlt.split(" ").length > 30) {
			setInvalidImageMessage(`Please enter appropriate alt text of a few words, upto 30(Important for SEO).`);
			return;
		}

		// Check if newImageCredit is too long
		if(newImageCredit.split(" ").length > 5) {
			setInvalidImageMessage(`The credit can not be of more than 5 words.`);
			return;
		}

		const existingImage = images.find((img) => img.imgSrc === imageBeingEdited);
		// console.log(existingImage);

		// replacing the src, alt and credit text for images  
		let updatedArticleContent = (articleContent as string)
		.replace(`src="${existingImage?.imgSrc}"`, `src="${newImageUrl}"`)
		.replace(`alt="${existingImage?.altTxt}"`, `alt="${newImageAlt}"`)
		.replace(`<sub>${existingImage?.credit}</sub>`, `<sub>${newImageCredit || ""}</sub>`)
		.replace(`![${existingImage?.altTxt}](${existingImage?.imgSrc})`, `![${newImageAlt}](${newImageUrl})`);

		// Replace unsplash image's sub tag along with its nested a tags with new credit sub tag
		// regex pattern to match sub tags with creditLink
			const subTagRegex = /<sub>(.*?)<\/sub>/g;
			// Iterate over each match of <sub> tag in the article content
			updatedArticleContent = updatedArticleContent.replace(subTagRegex, (match, subContent) => {
				// Check if the sub content includes the credit link
				if (subContent.includes(existingImage?.creditLink)) {
					// Replace the entire <sub> tag with the new credit text
					return `<sub>${newImageCredit}</sub>`;
				} else {
					// Return the original <sub> tag if it doesn't include the credit link
					return match;
				}
			});

		// add credit to ai generated image while replacing
		if(!existingImage?.credit && newImageCredit) {
			updatedArticleContent = updatedArticleContent.replace(`<img src="${newImageUrl}" alt="${newImageAlt}" style="width: 50%; height: auto; margin: 0 auto; display: block;" />`, 
			`<img src="${newImageUrl}" alt="${newImageAlt}" style="width: 50%; height: auto; margin: 0 auto; display: block;" /><br/><sub>${newImageCredit}</sub>`)
		}

		setArticleContent(updatedArticleContent);
		saveContent(updatedArticleContent);

		// reset states
		setNewImageUrl("");
		setNewImageAlt("");
		setNewImageCredit("");
		setInvalidImageMessage(undefined);
		setImageBeingEdited(undefined);
		setUploadedImage(null);
	}

	function handleImageRegeneration(imgSrc: string) {
		// show loading
		setImageBeingRegenerated(imgSrc);
		const existingImage = images.find((img) => img.imgSrc === imgSrc);
		// call the api
		if (articleUID && existingImage) {
			regenerateAIArticleImage.mutate({
				articleUID: articleUID,
				imageURL: existingImage.imgSrc
			}, {
				onSuccess: (data) => {
					// update the image with new url
					const updatedArticleContent = (articleContent as string).replace(existingImage.imgSrc, data.data.image_url);
					setArticleContent(updatedArticleContent);
					saveContent(updatedArticleContent);
					// reset states
					setNewImageUrl("");
					setNewImageAlt("");
					setNewImageCredit("");
					setInvalidImageMessage(undefined);
					setImageBeingEdited(undefined);
					setUploadedImage(null);
					// hide loading
					setImageBeingRegenerated(undefined);
				},
				onError: (error) => {
					console.log(error);
					setImageBeingRegenerated(undefined);
				},
			});
		}
	}

  // --------------------- MAIN JSX ------------------------

	return <>
		<div className={"article-images is-flex is-align-items-start is-flex-wrap-wrap is-justify-content-start"}>
			{images.map((image, index) =>
				<div key={`${image.imgSrc}${index}`} className={`article-img-container mt-3 ${imageBeingEdited === image.imgSrc ? "being-edited" : ""}`}>
					{imageBeingRegenerated === image.imgSrc ? <figure style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
						<Icon iconName={"spinner"} additionalClasses={["icon-primary" , "is-size-1"]} />
					</figure>
						: <figure>
							<img src={image.imgSrc} alt="article" />
							<figcaption>
								<a href={image.creditLink || image.imgSrc} target="_blank" rel="noreferrer">{image.credit}</a>
							</figcaption>
						</figure>
					}
					{imageBeingRegenerated === image.imgSrc ?  <div className="mt-4 mb-4 is-flex is-justify-content-center is-align-items-center">
						<p className="is-size-6">Regenerating Image...</p>
					</div> :
						<div className="mt-4 mb-2 is-flex is-justify-content-center">
							<AbunButton type={"danger"} className={"is-small is-outlined"} clickHandler={() => removeImageHandler(image.imgSrc)}>
								Remove
							</AbunButton>
							<AbunButton type={"success"} className={"is-small is-outlined ml-3"} clickHandler={() => setImageBeingEdited(image.imgSrc)}>
								Replace
							</AbunButton>
							{
								props.imageSource === "ai_image_generation" && image.imgSrc.includes("results.deepinfra.com") &&
								<AbunButton type={"primary"} className={"is-small is-outlined ml-3"} clickHandler={() => handleImageRegeneration(image.imgSrc)}>
									Regenerate
								</AbunButton>
							}
						</div>
					}
				</div>
			)}
		</div>
		{imageBeingEdited && <div className="mt-4">
			{!uploadedImage &&
				<div className="mb-4">
					<p className="mb-3 is-size-6">Enter new image URL*</p>
					<Input value={newImageUrl} className="edit-image-container" type={"text"} placeholder="enter image URL" onChange={
						(value) => setNewImageUrl(value)} />
				</div>}
			<div className="mb-4">
				<p className="mb-3 is-size-6">Enter alt text for the image*</p>
				<Input value={newImageAlt} className="edit-image-container" type={"text"} placeholder="enter alt text for the image" onChange={
					(value) => setNewImageAlt(value)} />
			</div>
			<div className="mb-4">
				<p className="mb-3 is-size-6">Enter Credit</p>
				<Input value={newImageCredit} className="edit-image-container" type={"text"} placeholder="enter credit" onChange={
					(value) => setNewImageCredit(value)} />
			</div>
			{invalidImageMessage && <p className="invalid-message my-4">{invalidImageMessage}</p>}
			<div className={"is-flex is-align-items-center is-flex-wrap-wrap-reverse"}>
				<AbunButton
					type={"success"}
					disabled={!newImageUrl || !newImageAlt}
					className={"mr-4 mt-2"}
					clickHandler={saveUpdatedImageHandler}>
					<Icon iconName={"floppy-disk"} additionalClasses={["icon-white", "mr-3"]} />
					Save
				</AbunButton>
				<ImageUpload
					additionalClasses={["mt-2"]}
					name={"article-image"}
					label={"Upload an Image"}
					onChange={handleImageUpload} />
			</div>
		</div>}
	</>
}


export function LinksTab(props: LinksTabProps) {
	// --------------------- STATES ------------------------
	const [articleLinks, setArticleLinks] = useState<Array<Link>>([]);
	const [editLink, setEditLink] = useState<string>();
	const [editedLinkHref, setEditedLinkHref] = useState<string>();
	const [newLinkHref, setNewLinkHref] = useState<string>("");
	const [newLinkPhrase, setNewLinkPhrase] = useState<string>("");
	const [invalidLinkMessage, setInvalidLinkMessage] = useState<string>();
	const { articleContent, currentTab, setArticleContent, saveContent } = props;

	// --------------------- LOADER ------------------------
	const basePageData = useRouteLoaderData("base") as BasePageData;
	const { active_website_domain } = basePageData;

	const linkType = currentTab === "internalLinks" ? "Internal" : "External";

	// --------------------- EFFECTS ------------------------

	useEffect(() => {
		if (articleContent) {
			 const articleLinks: Link[] = [];
  
			 // Regular expression to match entire <a> tags and Markdown-style links
			 const linkRegex = /<a\s+(?:[^>]*?\s+)?href="([^"]+)"(?:\s+rel="([^"]+)")?[^>]*>(.*?)<\/a>|\[([^\]]+)\]\(([^)]+)\)/g;
  
			 for (const match of articleContent.matchAll(linkRegex)) {
				  const linkHref = match[1] || match[5]; // Check which group matched for href
				  const linkTxt = match[3]?.replaceAll("*", "") || match[4]?.replaceAll("*", ""); // Check which group matched for link text
  
				  const rel = match[2]; // Check for rel attribute

				  if(linkHref.includes("unsplash")) continue; //exclude unsplash img credit links
  
				  const isInternal = linkHref.includes(active_website_domain as string);
				  const isExternal = !linkHref.includes(active_website_domain as string);
  
				  const link: Link = { linkHref, linkTxt };
  
				  // Add rel attribute if it exists
				  if (rel) {
						link.rel = rel;
				  }
  
				  if (currentTab === "internalLinks" && isInternal) {
						articleLinks.push(link);
				  } else if (currentTab === "externalLinks" && isExternal) {
						articleLinks.push(link);
				  }
			 }
  
			 setArticleLinks(articleLinks);
			 setInvalidLinkMessage("");
		}
	}, [currentTab, articleContent, active_website_domain]);

	// console.log(articleLinks);

	// --------------------- FUNCTIONS ------------------------
	function removeLinkHandler(link: Link) {
		// Escape special characters(., *, +, ?, ^, $, {, }, [, ], (, ), \, |, /) 
		// in linkTxt for the regex pattern
		const escapedLinkTxt = link.linkTxt.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

		// Regex pattern for <a> tag links
		const aTagRegex = new RegExp(`<a\\s+href="${link.linkHref}"(?:\\s+rel="[^"]*")?>${escapedLinkTxt}<\\/a>`, 'g');
		let updatedArticleContent = articleContent?.replace(aTagRegex, link.linkTxt);

		// Regex pattern for markdown link []() types
		const markdownLinkRegex = new RegExp(`\\[${escapedLinkTxt}\\]\\(${link.linkHref}\\)`, 'g');
		updatedArticleContent = updatedArticleContent?.replace(markdownLinkRegex, link.linkTxt);

		setArticleContent(updatedArticleContent);
		saveContent(updatedArticleContent);
	}


	// for saving link after editing url
	function saveEditedLinkHandler() {
		const linkToBeReplaced = articleLinks.find((link) => link.linkTxt === editLink);

		const newLinkUrl = editedLinkHref;

		// Check if new url is an HTML tag
		const htmlTagRegex = /<[^>]*>/g;
		if (htmlTagRegex.test(newLinkUrl as string)) {
			setInvalidLinkMessage(`${linkType} link URL cannot be an HTML tag.`);
			return;
		}

		// Check if new url is in valid URL format
		const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#]+\.[^\s]*$/i;
		if (!urlPattern.test(newLinkUrl as string)) {
			setInvalidLinkMessage(`Please enter a valid ${linkType} link URL.`);
			return;
		}

		// check if new url is of currently active tab => internal/external type
		if (currentTab === "internalLinks" &&
			!newLinkUrl?.includes(active_website_domain as string)) {
			setInvalidLinkMessage(`Please enter an ${linkType} link URL.`);
			return;
		} else if (currentTab === "externalLinks" &&
			newLinkUrl?.includes(active_website_domain as string)) {
			setInvalidLinkMessage(`Please enter an ${linkType} link URL.`);
			return;
		}

		//---------------- Replace the link ------------
		// Replace the link url of the <a></a> type link and []() with newLinkUrl 

		let updatedArticleContent: string;

		if (linkToBeReplaced?.rel) {// if it had rel attribute, for external links
			updatedArticleContent = (articleContent as string).replace(
				`<a href="${linkToBeReplaced?.linkHref}" rel="${linkToBeReplaced.rel}">${linkToBeReplaced?.linkTxt}</a>`,
				`<a href="${newLinkUrl}" rel="${linkToBeReplaced.rel}">${linkToBeReplaced?.linkTxt}</a>`)
		} else {
			updatedArticleContent = (articleContent as string).replace(
				`<a href="${linkToBeReplaced?.linkHref}">${linkToBeReplaced?.linkTxt}</a>`,
				`<a href="${newLinkUrl}">${linkToBeReplaced?.linkTxt}</a>`
			).replace(
				`[${linkToBeReplaced?.linkTxt}](${linkToBeReplaced?.linkHref})`,
				`[${linkToBeReplaced?.linkTxt}](${newLinkUrl})`
			);
		}

		setArticleContent(updatedArticleContent);
		saveContent(updatedArticleContent);
		setEditLink(undefined);
		setEditedLinkHref(undefined);
	}

	// for adding new link
	function addNewLinkHandler() {
		setInvalidLinkMessage("");
		if (!newLinkHref.trim().length || !newLinkPhrase.trim().length) {
			return;
		}

		// ---------------- validations --------------------

		// Check if newLinkPhrase is an HTML tag
		const htmlTagRegex = /<[^>]*>/g;
		if (htmlTagRegex.test(newLinkPhrase)) {
			setInvalidLinkMessage(`${linkType} link phrase cannot be an HTML tag.`);
			return;
		}

		// Check if newLinkHref is an HTML tag
		if (htmlTagRegex.test(newLinkHref)) {
			setInvalidLinkMessage(`${linkType} link URL cannot be an HTML tag.`);
			return;
		}

		// Check if newLinkPhrase is too small or too long
		if (newLinkPhrase.split(" ").length < 2 || newLinkPhrase.split(" ").length > 12) {
			setInvalidLinkMessage(`Please enter a phrase that is 2 to 12 words long.`);
			return;
		}

		// Check if newLinkHref is valid URL format
		const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#]+\.[^\s]*$/i;
		if (!urlPattern.test(newLinkHref)) {
			setInvalidLinkMessage(`Please enter a valid ${linkType} link URL.`);
			return;
		}

		// Check if newLinkPhrase is found in the articleContent
		if (articleContent?.indexOf(newLinkPhrase) === -1) {
			setInvalidLinkMessage(`No such phrase is found in the article content.`);
			return;
		}

		// Check if newLinkPhrase is part of any heading
		const headingRegex = /\n\n#{1,6}\s.*?\n\n/g;
		const headings = articleContent?.match(headingRegex) || [];
		const isInHeading = headings.some((heading) => heading.includes(newLinkPhrase));
		if (isInHeading) {
			// Phrase is part of a heading, do not replace
			setInvalidLinkMessage(
				`${linkType} link phrase cannot be part of the heading. Please enter another phrase or insert the link using editor.`
			);
			return;
		}

		// regex pattern to match newLinkPhrase(excludes in titles/headings)
		const phraseRegex = new RegExp(`(?<!##\\s)\\b${newLinkPhrase}\\b(?!\\s##)`, "gi");
		// Match occurrences of newLinkPhrase in the article content
		const phraseMatches = articleContent?.match(phraseRegex);
		if (phraseMatches && phraseMatches.length > 1) {
			setInvalidLinkMessage(`The phrase appears multiple times. Please add more words around it or use the editor to insert the link.`);
			return;
		}

		// check if the newLinkPhrase is already a link text or part of any link text
		// for <a></a> tags
		const linkTextsFromATags: string[] = [];
		const linkRegexForATags = new RegExp(/<a\s+(?:[^>]*?\s+)?href="([^"]+)"[^>]*>(.*?)<\/a>/g);
		let match: RegExpExecArray | null;
		while ((match = linkRegexForATags.exec(articleContent as string)) !== null) {
			linkTextsFromATags.push(match[2]);
		}

		// for []() type of links
		const linkTextsFromMarkdownLinks: string[] = [];
		const markdownLinkRegex = new RegExp(/\[(.*?)\]\((.*?)\)/g);
		while ((match = markdownLinkRegex.exec(articleContent as string)) !== null) {
			linkTextsFromMarkdownLinks.push(match[1]);
		}

		const allLinkTexts = [...linkTextsFromATags, ...linkTextsFromMarkdownLinks];

		let isPhraseMatched = false;
		for (const text of allLinkTexts) {
			if (text === newLinkPhrase || text.includes(newLinkPhrase)) {
				isPhraseMatched = true;
				break;
			}
		}

		if (isPhraseMatched) {
			setInvalidLinkMessage(`The phrase is already a link or part of a link, please edit its URL from the ${linkType} links tab or add the link using editor.`);
			return;
		}


		// ---------------- ADD THE LINK --------------------
		// Add link by replacing the text with an anchor tag after all validations are passed

		let linkToAdd = `<a href="${newLinkHref}">${newLinkPhrase}</a>`;

		if ((
			props.externalBacklinksPreference === "no-follow" ||
			props.externalBacklinksPreference === "follow")
			&& !newLinkHref.includes(active_website_domain as string) // external link
		) {
			const preference = props.externalBacklinksPreference.replace("-", "");
			//if its an external link and externalBacklinksPreference is set, add new link accordingly
			linkToAdd = `<a href="${newLinkHref}" rel="${preference}">${newLinkPhrase}</a>`;
		}

		const updatedArticleContent = articleContent?.replace(
			newLinkPhrase, linkToAdd
		);

		setArticleContent(updatedArticleContent);
		saveContent(updatedArticleContent);
		setNewLinkHref("");
		setNewLinkPhrase("");

		// Changing tabs based on if a different type of link was entered than active tab
		if (currentTab === "internalLinks" && !newLinkHref.includes(active_website_domain as string)) {
			props.setCurrentTab("externalLinks");
		} else if (currentTab === "externalLinks" && newLinkHref.includes(active_website_domain as string)) {
			props.setCurrentTab("internalLinks");
		}

	}


	// --------------------- COLUMN DEFS ------------------------
	const columnHelper = createColumnHelper<Link>();
	const columnDefs: ColumnDef<any, any>[] = [
		columnHelper.accessor((row: Link) => row.linkTxt, {
			id: 'linkTxt',
			header: "Anchor Text",
			cell: info => info.getValue(),
			enableGlobalFilter: true,
		}),
		columnHelper.accessor((row: Link) => row.linkHref, {
			id: 'linkHref',
			header: "Link URL",
			cell: info => {
				if (editLink === info.row.original.linkTxt) {
					return <div className={"is-flex is-align-items-center"}>
						<Input value={editedLinkHref || ""} type={"text"} autofocus={true} className="edit-link-container" onChange={(linkHref) => setEditedLinkHref(linkHref)} />
						<AbunButton type={"success"}
							disabled={!editedLinkHref?.length}
							className={"ml-2 is-small"}
							clickHandler={saveEditedLinkHandler}>
							<Icon iconName={"floppy-disk"} additionalClasses={["icon-white", "mr-3"]} />
							Save
						</AbunButton>
						<AbunButton type={"primary"}
							className={"ml-2 is-small"}
							clickHandler={() => { setEditLink(undefined) }}>
							Cancel
						</AbunButton>
					</div>
				} else {
					let cellValue = info.getValue();
					if (cellValue.length > 90) {
						cellValue = cellValue.substring(0, 89) + "..."; // truncating long urls
					}
					return <div className="is-flex is-align-items-flex-start is-justify-content-space-between">
						<a href={info.getValue()} className="link-container is-block" target="_blank" rel="noreferrer">{cellValue}</a>
						<Icon iconName={"edit"}
							width={"1.5em"}
							additionalClasses={["icon-grey", "ml-3", "is-block", "icon-cursor-pointer"]}
							onClick={() => {
								setEditLink(info.row.original.linkTxt);
								setEditedLinkHref(info.getValue());
							}} />
					</div>
				}
			},
			enableGlobalFilter: true,
		}),
		columnHelper.display({
			id: 'removeLink',
			header: "Action",
			cell: info => {
				const link = {
					linkHref: info.row.original.linkHref,
					linkTxt: info.row.original.linkTxt,
				}
				return <AbunButton type={"danger"} className={"is-small is-outlined"} clickHandler={() => removeLinkHandler(link)}>
					Remove
				</AbunButton>
			},
			enableGlobalFilter: false,
			meta: {
				align: 'center'
			}
		}),
	]

	// --------------------- MAIN JSX ------------------------
	return <div className={"mt-3"}>
		<AbunTable tableContentName={`${linkType}`}
			tableData={articleLinks}
			columnDefs={columnDefs}
			pageSizes={[10]}
			hidePagination={true}
			noDataText={`No ${linkType} links found...`}
			initialPageSize={10} />
		<div className={"mt-4"}>
			<div className="mb-4">
				<p className="mb-3 is-size-6">Enter article phrase*</p>
				<Input value={newLinkPhrase} className="add-link-container" type={"text"} placeholder={`enter the phrase from article that you want to set as ${linkType} link`} onChange={(value) => setNewLinkPhrase(value)} />
			</div>
			<div className="mb-4">
				<p className="mb-3 is-size-6">Enter {linkType} link URL*</p>
				<Input value={newLinkHref} className="add-link-container" type={"text"} placeholder={`enter ${linkType} link URL`} onChange={(value) => setNewLinkHref(value)} />
			</div>
			{invalidLinkMessage && <p className="invalid-message my-4">{invalidLinkMessage}</p>}
			<AbunButton type={"success"}
				disabled={!newLinkHref || !newLinkPhrase}
				clickHandler={addNewLinkHandler}>
				<Icon iconName={"floppy-disk"} additionalClasses={["icon-white", "mr-3"]} />
				Add
			</AbunButton>
		</div>
	</div>
}


export function FeaturedImagesTab(props: FeaturedImagesTabProps) {
	// --------------------- STATES ------------------------
	const [tempForSelection, setTempForSelection] = useState<string>("");
	const [showTemplateConfirmModal, setShowTemplateConfirmModal] = useState<boolean>(false);

	// ---------------------------- MUTATIONS ----------------------------
	const saveArticleFeaturedImg = useMutation(saveArticleFeaturedImgMutation);

	// --------------------- FUNCTIONS ----------------------------

	function handleTemplateSelection(templateId: string) {
		props.errorAlertRef.current?.close();
		props.successAlertRef.current?.close();
		saveArticleFeaturedImg.mutate(
			{
				article_type: props.articleUid.includes("how-to") ? "how-to" : "general",
				article_uid: props.articleUid,
				template_id: templateId
			}, {
			onSuccess: (data) => {
				props.successAlertRef.current?.show("Featured image updated successfully.");
				props.setFeaturedImageURL(data.data.image_url);
				props.setSelectedTemplate(templateId);
				setTempForSelection("");
				setShowTemplateConfirmModal(false);
			},
			onError: () => {
				props.errorAlertRef.current?.show("Oops! Failed to update :( Please try again later or contact us for further support.");
			}
		});
	}

	// --------------------- MAIN JSX ------------------------
	return <>
		<AbunModal active={showTemplateConfirmModal}
			headerText={"Please Confirm!"}
			closeable={false}
			hideModal={() => {
				setShowTemplateConfirmModal(false);
			}}>
			<div className="p-3 is-bold has-text-centered">
				<p className="is-size-5 mb-3">Are you sure you want to update the Featured Image?</p>
				<div className="is-flex is-justify-content-center">
					<AbunButton type={"success"}
						className={"mt-3 mr-2"}
						clickHandler={() => handleTemplateSelection(tempForSelection)}>
						{!saveArticleFeaturedImg.isLoading ? "Yes, Update" : "Updating..."}
					</AbunButton>
					{
						!saveArticleFeaturedImg.isLoading &&
						<AbunButton type={"primary"}
							className={"mt-3 ml-2"}
							clickHandler={() => {
								setShowTemplateConfirmModal(false);
								setTempForSelection("");
							}}>
							Go Back
						</AbunButton>
					}
				</div>
			</div>
		</AbunModal>
		<div className={"featured-images is-flex is-align-items-start is-flex-wrap-wrap is-justify-content-start"}>
			{props.featuredImgTemplates.map((template, index) =>
				<div key={`${template.sample_image_url}${index}`} className={`featured-img-container mt-3 is-flex is-align-items-center is-justify-content-center ${props.selectedTemplate === template.template_id ? "selected-img" : ""}`}>
					<div>
						<img className="is-clickable is-block" onClick={() => setTempForSelection(template.template_id)} src={template.sample_image_url} alt="article" />
						{
							(tempForSelection === template.template_id && props.selectedTemplate !== template.template_id) &&
							<AbunButton type={"success"}
								className={"mt-3 is-small"}
								clickHandler={() => setShowTemplateConfirmModal(true)}>
								<Icon iconName={"floppy-disk"} additionalClasses={["icon-white", "mr-3"]} />
								Use Template
							</AbunButton>
						}
					</div>
				</div>)}
		</div>
	</>
}

export function MetaDataTab(props: MetaDataTabProps) {
	// --------------------- STATES ------------------------
	const { articleMetaDescription, setArticleMetaDescription, saveContent } = props;
	const [ metaDescription, setMetaDescription ] = useState<string>("");

	// --------------------- EFFECTS ------------------------
	useEffect(() => {
		setMetaDescription(articleMetaDescription);
	}, [articleMetaDescription, setMetaDescription]);

	// --------------------- FUNCTIONS ------------------------
	// save the meta description
	function saveMetaData() {
		if(metaDescription) {
			console.log("metaDescription: ", metaDescription);
			setArticleMetaDescription(metaDescription);
			saveContent("",metaDescription);
		}
	}

	// --------------------- MAIN JSX ------------------------
	return <div className={"mt-3 mb-4 w-60"}>
			{
				!props.articlePosted &&
				<div className="mb-4">
					<p className="mb-3 is-size-6">Edit Article Meta Description:</p>
					<TextArea value={metaDescription} className="edit-meta-container" placeholder="enter meta description" onChange={(value) => setMetaDescription(value)} />
				</div>
			}
			{
				props.articlePosted &&
				<div className="mb-4">
					<p className="mb-3 is-size-6">Article Meta Description:</p>
					{/* read only text area */}
					<div className="read-only-pseudo-textarea">
						{metaDescription}
					</div>
				</div>
			}
		<AbunButton type={"success"}
			className={props.articlePosted ? "is-hidden" : ""}
			disabled={!metaDescription || metaDescription === props.articleMetaDescription}
			clickHandler={saveMetaData}>
			<Icon iconName={"floppy-disk"} additionalClasses={["icon-white", "mr-3"]} />
			Save
		</AbunButton>
	</div>
}
